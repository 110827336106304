import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Tooltip } from 'react-tippy'

class BasicSelect extends React.PureComponent {
	static propTypes = {
		name: PropTypes.string,
		onChange: PropTypes.func,
		customStyles: PropTypes.shape(),
		showInlineLabel: PropTypes.bool,
		labelTooltip: PropTypes.string
	}

	state = {
		open: false
	}

	constructor(props) {
		super(props)
		this.selectRef = React.createRef()
	}

	handleMenuOpen = () => {
		this.setState({
			open: true
		})
	}

	handleBlur = () => {
		const focusedElement = document.activeElement
		if (focusedElement && !focusedElement.className.includes('react-select')) {
			this.setState({
				open: false
			})
		} else {
			this.selectRef.current.focus()
		}
	}

	handleOnChange = (value, action) => {
		this.setState({
			open: false
		})

		const { onChange } = this.props
		if (onChange) {
			onChange(value, action)
		}
	}

	handleChangeMultiple = (val, action) => {
		const { onChange } = this.props
		const values = val || []
		onChange(values, action)
	}

	render() {
		const { name, customStyles, showInlineLabel, ...props } = this.props
		const { open } = this.state

		let value = props.value !== undefined ? props.value : null
		let handleChange
		if (props.isMulti) {
			handleChange = this.handleChangeMultiple
			if (!value) {
				value = []
			}
		} else {
			handleChange = this.handleOnChange
		}
		return (
			<div className={cx('select-wrapper', { 'label-inline': showInlineLabel }, { 'has-error': !!props.error })}>
				{props.labelTooltip && props.label && (
					<Tooltip isOpen html={props.labelTooltip} position='top' trigger='mouseenter' theme='light'>
						<label className='tooltip-label'>{props.label}</label>
					</Tooltip>
				)}
				{!props.labelTooltip && props.label && <label>{props.label}</label>}
				<Select
					classNamePrefix='react-select'
					{...props}
					name={name}
					value={value}
					noOptionsMessage={() => 'Nič sa nenašlo.'}
					// NOTE: next lines are for IE hot fix https://github.com/JedWatson/react-select/issues/1020
					onChange={handleChange}
					onMenuOpen={this.handleMenuOpen}
					menuIsOpen={open}
					onBlur={this.handleBlur}
					ref={this.selectRef}
					styles={customStyles}
				/>
				{props.error && <div className='text-danger'>{props.error}</div>}
			</div>
		)
	}
}

export default BasicSelect
