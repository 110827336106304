import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Field, touch, change } from 'redux-form'

// components
import AddressField from '../../../atoms/NewAddressFields/AddressField'

import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'
import { formatAddress, getAddressFromAddressId } from '../../../utils/address'
import CheckboxField from '../../../atoms/CheckboxField'

class AdresaPostovePoukazkyField extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		dispatch: PropTypes.func.isRequired,
		editMode: PropTypes.string.isRequired,
		field: PropTypes.string.isRequired,
		value: PropTypes.shape().isRequired,
		originalValue: PropTypes.shape(),
		columnsCount: PropTypes.string,
		formValues: PropTypes.shape().isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			showEditAdresaOdoslanePlatby: false
		}
	}

	validate = (value, formValues) => {
		const { t, povinny } = this.props
		const { showEditAdresaOdoslanePlatby } = this.state

		if (povinny && !value) {
			return t('components:GenericFields.Adresa pre doručovanie poštových poukážok je povinná')
		}

		if (showEditAdresaOdoslanePlatby && !value) {
			return t('components:GenericFields.Vyberte adresu pre doručovanie poštových poukážok')
		}

		if (showEditAdresaOdoslanePlatby && value?.id === formValues?.data?.adresaKorespondencna?.id) {
			return t('components:GenericFields.Adresa pre doručovanie poštových poukážok musí byť odlišná od fakturačnej adresy')
		}
	}

	handleChangeCheckbox = (newValue) => {
		const { dispatch, form, field } = this.props

		dispatch(touch(form, field))
		if (newValue === false) {
			dispatch(change(form, field, null))
		}

		this.setState({
			showEditAdresaOdoslanePlatby: newValue
		})
	}

	render() {
		const { t, field, value, originalValue, columnsCount, editMode, adresyPosta } = this.props
		const { showEditAdresaOdoslanePlatby } = this.state

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('components:GenericFields.Adresa pre doručovanie poštových poukážok')}</strong>
							</td>
							{columnsCount === COLUMNS_COUNT.THREE && <td>{formatAddress(originalValue)}</td>}
							{(editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP || editMode == EDIT_MODE.CREATE) && (
								<td>
									{showEditAdresaOdoslanePlatby && (
										<div style={{ marginBottom: '10px' }}>
											<Field name={field} create edit component={AddressField} addresses={adresyPosta} validate={this.validate} />
										</div>
									)}

									<Field
										value={showEditAdresaOdoslanePlatby}
										onChange={this.handleChangeCheckbox}
										component={CheckboxField}
										label={t('components:GenericFields.Nastaviť odlišnú adresu pre doručovanie poštových poukážok')}
									/>
								</td>
							)}
							{(editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) && (
								<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
									<div className='input-wrapper'>{formatAddress(value)}</div>
								</td>
							)}
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	addresses: state.formAddresses.data
})

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(AdresaPostovePoukazkyField)
