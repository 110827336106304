import React from 'react'
import { connect } from 'react-redux'
import { Field, FieldArray } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { get, isEmpty, size } from 'lodash'

// atoms
import TextInputField from '../../../atoms/TextInputField'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { containsDiacritics, isEmail } from '../../../utils/email'

// resources
import addCrossIcon from '../../../resources/img/icons/add-cross.svg'

class EmailEfaktura extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.string,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape(),
		povinny: PropTypes.bool.isRequired
	}

	constructor(props) {
		super(props)
	}

	_mounted = false

	componentDidMount() {
		this._mounted = true

		const { field, dispatch, change, editMode, opEmailEfaktury } = this.props

		if (editMode === EDIT_MODE.CONFIRM || editMode === EDIT_MODE.DETAIL) {
			return
		}

		let initEmails = []
		if (Array.isArray(opEmailEfaktury) && opEmailEfaktury.length > 0) {
			initEmails = opEmailEfaktury
		}

		dispatch(change(field, initEmails))
	}

	checkIfArraysAreEqual = (array1, array2) => {
		if (array1?.toString() === array2?.toString()) {
			return true
		}

		return false
	}

	renderArray = (arrayOfStrings, renderRegularValue = true) => {
		if (arrayOfStrings == undefined || arrayOfStrings.length === 0) {
			return '-'
		}

		return arrayOfStrings.map((string, index) => {
			return <div key={index}>{renderRegularValue ? string : <strong>{string}</strong>}</div>
		})
	}

	renderEmailFields = ({ fields }) => {
		const { t } = this.props

		return (
			<>
				{fields.map((member, index) => (
					<div key={index} style={{ display: 'flex', alignItems: 'flex-start', gap: '10px', marginBottom: '10px' }}>
						<div style={{ flexGrow: 1 }}>
							<Field
								name={member}
								component={TextInputField}
								placeholder={t('components:GenericFields.Zadajte email pre e-faktúru')}
								validate={this.validate}
							/>
						</div>
						<img
							style={{ width: '20px', height: '20px', marginTop: '4px', cursor: 'pointer' }}
							onClick={() => fields.remove(index)}
							src={addCrossIcon}
						/>
					</div>
				))}

				<div style={{ display: 'flex', gap: '10px' }}>
					<button className='button small noselect' type='button' data-type='outline' data-color='blue' onClick={() => fields.push('')}>
						{t('components:GenericFields.Pridať email')}
					</button>
					{fields.length > 0 && (
						<button className='button small noselect' type='button' data-type='outline' data-color='red' onClick={() => fields.removeAll()}>
							{t('components:GenericFields.Deaktivovať e-faktúru')}
						</button>
					)}
				</div>
			</>
		)
	}

	validate = (email, formData) => {
		const { t } = this.props

		const opEmailEfaktury = get(formData, 'data.opEmailEfaktury', [])

		if (isEmpty(email)) {
			return t('components:GenericFields.Pole je prázdne')
		}

		if (!isEmail(email)) {
			return t('components:GenericFields.Email pre e-faktúru nie je platný')
		}

		if (containsDiacritics(email)) {
			return t('components:GenericFields.Email nesmie obsahovať diakritiku')
		}

		if (size(email) > 2048) {
			return t('components:GenericFields.Email môže mať najviac 2048 znakov')
		}

		if (opEmailEfaktury?.length > 1) {
			const hasDuplicates = new Set(opEmailEfaktury).size !== opEmailEfaktury.length
			if (hasDuplicates) {
				return t('components:GenericFields.Emaily pre e-faktúru sa nesmú opakovať')
			}
		}
	}

	render() {
		const { value, originalValue, cesta, editMode, columnsCount = COLUMNS_COUNT.THREE, t } = this.props

		if (this._mounted === false) return
		if (Array.isArray(value) === false) {
			console.error('AttrOPEmailEfakturaDTO: value is not an array')
		}
		const isSameArray = this.checkIfArraysAreEqual(originalValue, value)

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('components:GenericFields.E-faktúra')}</strong>
							</td>
							{(editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.CREATE) && (
								<>
									{columnsCount === COLUMNS_COUNT.THREE && <td>{this.renderArray(originalValue)}</td>}
									<td>
										<FieldArray name={cesta} component={this.renderEmailFields} />
									</td>
								</>
							)}
							{(editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) && (
								<>
									{columnsCount === COLUMNS_COUNT.THREE && <td>{this.renderArray(originalValue)}</td>}
									<td>{this.renderArray(value, isSameArray)}</td>
								</>
							)}
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapDispatchToProps))(EmailEfaktura)
