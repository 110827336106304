import React from 'react'
import { get, upperFirst } from 'lodash'
import PropTypes from 'prop-types'

class UkonVseobecnyAktivny extends React.Component {
	static propTypes = {
		ukon: PropTypes.shape(),
		ukonData: PropTypes.shape(),
		dokumenty: PropTypes.array,
		formatDocuments: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired
	}

	render() {
		const { ukonData, dokumenty, formatDocuments, t } = this.props

		return (
			<tbody>
				<tr>
					<td>
						<strong>{t('translation:Common.Číslo miesta spotreby')}</strong>
					</td>
					<td>{get(ukonData, 'miestoSpotreby.cislo')}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:VseobecnyAktivnyUkon.Kategória 1')}</strong>
					</td>
					<td>{upperFirst(get(ukonData, 'ukonKategoria1.nazov'))}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:VseobecnyAktivnyUkon.Kategória 2')}</strong>
					</td>
					<td>{upperFirst(get(ukonData, 'ukonKategoria2.nazov'))}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:VseobecnyAktivnyUkon.Kategória 3')}</strong>
					</td>
					<td>{upperFirst(get(ukonData, 'ukonKategoria3.nazov', ''))}</td>
				</tr>
				<tr>
					<td>
						<strong>{t('translation:VseobecnyAktivnyUkon.Text žiadosti')}</strong>
					</td>
					<td>{get(ukonData, 'ziadostText')}</td>
				</tr>
				<tr>
					<td colSpan={2}>{formatDocuments(dokumenty)}</td>
				</tr>
			</tbody>
		)
	}
}

export default UkonVseobecnyAktivny
