import React from 'react'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { size } from 'lodash'

// atoms
import { TextareaField } from '../../../atoms'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

class TextField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.string,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		placeholder: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		povinny: PropTypes.bool.isRequired
	}

	state = {}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { t, povinny } = this.props

		if (value && size(value) > 2000) {
			return t('translation:Common.validate.Maximálna dĺžka je 2000 znakov')
		}

		if (povinny && !value) {
			return t('translation:Common.validate.Toto pole je povinné!')
		}
	}

	render() {
		const { editMode, columnsCount = COLUMNS_COUNT.THREE, value, label, field, placeholder } = this.props

		if (editMode == EDIT_MODE.DETAIL) {
			return null
		}

		const valueBefore = <td />
		let valueAfter = null

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP || editMode == EDIT_MODE.CREATE) {
			valueAfter = (
				<td>
					<Field name={field} component={TextareaField} rows='3' validate={this.validate} placeholder={placeholder} />
				</td>
			)
		} else if (editMode == EDIT_MODE.CONFIRM) {
			valueAfter = (
				<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
					<div className='input-wrapper'>
						<textarea disabled='disabled' rows='5' className='form-control static' style={{ backgroundColor: 'white' }} value={value} />
					</div>
				</td>
			)
		}

		return (
			<table className='content-table padded bordered' cellSpacing='0'>
				<tbody>
					<tr>
						<td>
							<strong>{label}</strong>
						</td>
						{columnsCount === COLUMNS_COUNT.THREE && valueBefore}
						{valueAfter}
					</tr>
				</tbody>
			</table>
		)
	}
}

export default compose(withTranslation('components'))(TextField)
