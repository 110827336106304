import React from 'react'
import { connect } from 'react-redux'
import { Field, change } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { get, isEmpty, forEach, size } from 'lodash'
import cx from 'classnames'

// atoms
import { UploadField } from '../../../atoms'
import Select from '../../../atoms/BasicSelect'

// components
import DefaultModal from '../../Modals/DefaultModal'

// config
import { EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { deleteReq } from '../../../utils/request'
import { FORMS } from '../../../utils/enums'

class DokumentyField extends React.Component {
	static propTypes = {
		label: PropTypes.string,
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		dokumentTyp: PropTypes.shape(),
		dokumentDruhy: PropTypes.shape(),
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		povinny: PropTypes.bool.isRequired,
		meta: PropTypes.shape({
			touched: PropTypes.bool,
			error: PropTypes.string
		}).isRequired
	}

	state = {
		dokumentDruhOptions: [],
		druhDokumentuModal: {
			visible: false,
			index: null,
			selectedDruhDokumentu: null
		}
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true

		const { dokumentDruhy } = this.props

		const dokumentDruhOptions = dokumentDruhy?.map((DokumentDruhDTO) => {
			const dokumentDruh = DokumentDruhDTO?.dokumentDruh

			return {
				label: dokumentDruh.nazov,
				value: dokumentDruh.id
			}
		})

		this.setState({
			dokumentDruhOptions
		})
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (dokumenty) => {
		const { povinny, t } = this.props

		if (povinny && isEmpty(dokumenty)) {
			return t('translation:Common.validate.Dokumenty sú povinné')
		}

		if (!isEmpty(dokumenty)) {
			forEach(dokumenty, (document) => {
				if (!get(document, 'dataAsBase64')) {
					return t('translation:Common.validate.Neplatný dokument', {
						nazovDokumentu: get(document, 'name')
					})
				}
			})
		}
	}

	onChangeDokumenty = (e, newFiles, originalFiles) => {
		const { field } = this.props
		const { dokumentDruhOptions } = this.state

		if (!field) {
			return
		}

		if (isEmpty(dokumentDruhOptions)) {
			return
		}

		const newFileIndex = size(newFiles) - 1

		// NOTE: select default value for ziadostTyp atribute based on CP-2980, CP-3291 request
		let dokumentDruhDefaultValue = null
		if (dokumentDruhOptions?.length === 1) {
			dokumentDruhDefaultValue = dokumentDruhOptions[0]
		}

		if (size(newFiles) >= size(originalFiles)) {
			this.setState({
				druhDokumentuModal: {
					visible: true,
					index: newFileIndex,
					selectedDruhDokumentu: dokumentDruhDefaultValue
				}
			})
		}
	}

	handleDruhDokumentuChange = (selectedOption) => {
		const { druhDokumentuModal } = this.state

		this.setState({
			druhDokumentuModal: {
				...druhDokumentuModal,
				selectedDruhDokumentu: selectedOption
			}
		})
	}

	saveDruhDokumentuOption = (savedDruhDokumentu, fileIndex) => {
		const { dokumentDruhy } = this.props

		const { field, dispatch } = this.props

		this.setState({
			druhDokumentuModal: {
				visible: false,
				index: null,
				selectedDruhDokumentu: null
			}
		})

		if (!savedDruhDokumentu) return

		const dokumentDruhOption = dokumentDruhy?.find((dokumentDruhOption) => dokumentDruhOption?.dokumentDruh?.id === savedDruhDokumentu?.value)
		const dokumentDruhValue = dokumentDruhOption?.dokumentDruh

		dispatch(change(FORMS.GENERIC_UKON, `${field}[${fileIndex}].dokumentDruh`, dokumentDruhValue))
	}

	onChangeDokumentDruh = (index) => {
		this.setState({
			druhDokumentuModal: {
				visible: true,
				index
			}
		})
	}

	// TODO: Ak je nahraný súbor, umožniť jeho odstránenie. Aktuálne komponent DropZoneField neumožňuje odstránenie súboru po nahratí.
	onDeleteUploadedFile = async (fileID) => {
		const { ukonNovy } = this.props

		try {
			await deleteReq(`/api/v0/ukony/${get(ukonNovy, 'id')}/dokumenty/${fileID}`)
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		}
	}

	render() {
		const { label, field, editMode, t, dokumentTyp } = this.props
		const { druhDokumentuModal, dokumentDruhOptions } = this.state

		const dokumentDruhValue = dokumentDruhOptions?.find((options) => options.value === druhDokumentuModal.selectedDruhDokumentu?.value)

		if (editMode == EDIT_MODE.LOOKUP || editMode == EDIT_MODE.CREATE || editMode == EDIT_MODE.EDIT) {
			return (
				<>
					{druhDokumentuModal.visible && (
						<DefaultModal
							modalTitle={t('components:GenericFields.Zvoľte druh vstupného dokumentu')}
							modalContent={
								<Select
									classNamePrefix='react-select'
									value={dokumentDruhValue}
									placeholder={t('components:GenericFields.Vyberte druh vstupného dokumentu')}
									options={dokumentDruhOptions}
									onChange={(newValue) => this.handleDruhDokumentuChange(newValue, druhDokumentuModal.index)}
									isSearchable={false}
									isDisabled={isEmpty(dokumentDruhOptions)}
								/>
							}
							leftButton={{
								onClick: () => this.saveDruhDokumentuOption(druhDokumentuModal.selectedDruhDokumentu, druhDokumentuModal.index),
								text: t('translation:Common.Pokračovať'),
								disabled: isEmpty(dokumentDruhValue)
							}}
							visible
						/>
					)}
					<div className={'generic-field'}>
						<Field
							labelText={label || t('components:GenericFields.Vstupné dokumenty')}
							name={field}
							component={UploadField}
							onChange={this.onChangeDokumenty}
							validate={this.validate}
							multiple={false}
							onDelete={this.onDeleteUploadedFile}
							placeholder={t('components:GenericFields.Klikni alebo presuň súbory pre nahratie')}
							dokumentTyp={dokumentTyp}
							showChangeBtnDokumentDruh={dokumentDruhOptions?.length > 0}
							onChangeDokumentDruh={this.onChangeDokumentDruh}
						/>
					</div>
				</>
			)
		}

		return null
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(null, mapDispatchToProps))(DokumentyField)
