import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

// config
import { COLUMNS_COUNT } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { formatFormValue } from '../../../utils/form'

class ZmluvnyVztahCisloField extends React.Component {
	static propTypes = {
		columnsCount: PropTypes.number,
		originalValue: PropTypes.shape(),
		value: PropTypes.shape(),
		t: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	render() {
		const { columnsCount = COLUMNS_COUNT.THREE, originalValue, value, t } = this.props

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('components:GenericFields.Číslo zmluvy CRM')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td>{originalValue}</td>}
							<td>{value ? formatFormValue(value, originalValue) : '-'}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

export default compose(withTranslation('components'))(ZmluvnyVztahCisloField)
