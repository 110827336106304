import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { isEmpty } from 'lodash'

// atoms
import { SelectField } from '../../../../atoms'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../../containers/GenericUkon/genericUkonConfig'

// utils
import { formatFormValue } from '../../../../utils/form'

class PripomienkaPlatbyField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape(),
		wrapIntoContainer: PropTypes.bool
	}

	state = {
		neposielatPripomienkuPlatbyOptions: []
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true

		const { t } = this.props

		if (this._mounted) {
			this.setState({
				neposielatPripomienkuPlatbyOptions: [
					{
						value: true,
						label: t('translation:Common.Neaktívne')
					},
					{
						value: false,
						label: t('translation:Common.Aktívne')
					}
				]
			})
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, value, originalValue, t, wrapIntoContainer = true } = this.props
		const { neposielatPripomienkuPlatbyOptions } = this.state

		let valueBefore = null
		let valueAfter = null

		if (originalValue) {
			valueBefore = t('translation:Common.Neaktívne')
		} else if (originalValue == false) {
			valueBefore = t('translation:Common.Aktívne')
		}

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP) {
			valueAfter = (
				<Field
					name={field}
					component={SelectField}
					options={neposielatPripomienkuPlatbyOptions}
					isSearchable={false}
					isDisabled={isEmpty(neposielatPripomienkuPlatbyOptions)}
					classNamePrefix='react-select'
				/>
			)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			if (value) {
				valueAfter = t('translation:Common.Neaktívne')
			} else if (value == false) {
				valueAfter = t('translation:Common.Aktívne')
			}
			if (columnsCount == COLUMNS_COUNT.THREE) {
				valueAfter = formatFormValue(valueAfter, valueBefore)
			}
		}

		const content = (
			<tr>
				<td>
					<strong>{t('translation:Common.Pripomienka platby')}</strong>
				</td>
				{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
				<td>{valueAfter}</td>
			</tr>
		)

		if (wrapIntoContainer) {
			return (
				<div className='inner-box'>
					<table className='content-table padded bordered' cellSpacing='0'>
						<tbody>{content}</tbody>
					</table>
				</div>
			)
		}

		return content
	}
}

export default compose(withTranslation('components'))(PripomienkaPlatbyField)
