import React from 'react'
import { touch, Field } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'

import { get, filter, map, upperFirst } from 'lodash'

// atoms
import { SelectField, NumericInputField, DebounceFieldWrapper } from '../../../atoms'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { formatFormValue } from '../../../utils/form'

// components
import BooleanField from './BooleanField'
import { calculateYearAdvancePayment } from '../../../utils/platby'

class ZalohyField extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		field: PropTypes.string.isRequired,
		form: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		povinny: PropTypes.bool.isRequired,
		ciselniky: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape()
	}

	state = {}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true

		const { dispatch, field, form, editMode } = this.props

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP) {
			dispatch(touch(form, `${field}.zalohySuma`))
			dispatch(touch(form, `${field}.zalohyNeznizovatSumu`))
			dispatch(touch(form, `${field}.zalohyCyklus`))
			dispatch(touch(form, `${field}.zalohyDenSplatnosti`))
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validateZalohySuma = (fieldValue) => {
		const { povinny, label, t } = this.props

		if (povinny) {
			if (fieldValue == null || fieldValue == undefined || fieldValue <= 0) {
				return t('translation:Common.validate.Výška záloh musí byť vyššia ako 0', { label })
			}
		}
	}

	validateZalohyCyklus = (fieldValue) => {
		const { povinny, t } = this.props

		if (povinny) {
			if (fieldValue == null || fieldValue == undefined) {
				return t('translation:Common.validate.Nevyhovujúci cyklus záloh')
			}
		}
	}

	validateZalohyDenSplatnosti = (fieldValue) => {
		const { povinny, t } = this.props

		if (povinny) {
			if (fieldValue == null || fieldValue == undefined) {
				return t('translation:Common.validate.Nevyhovujúci deň splatnosti záloh')
			}
		}
	}

	debounceNumericInput = DebounceFieldWrapper(NumericInputField, 100)

	formatValueVyskaZaloh = (num) => {
		return num != null && num != undefined ? `${num.toString().split('.').join(',')} €` : ''
	}

	customParseVyskaZaloh = (stringValue) => {
		const val = stringValue.toString().split(',').join('.').replace(/\s€/, '')
		return parseInt(val)
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, ciselniky, value, originalValue, t } = this.props

		const zalohyCyklus = filter(get(ciselniky, 'zalohaCyklus', []), (zalohaCyklus) => {
			return get(zalohaCyklus, 'id') > 2 && get(zalohaCyklus, 'id') < 7
		})
		const zalohyCyklusOptions = map(zalohyCyklus, (zalohaCyklus) => ({
			label: upperFirst(get(zalohaCyklus, 'nazov')),
			value: zalohaCyklus
		}))

		const zalohyDenSplatnostiOptions = map(get(ciselniky, 'zalohaDenSplatnosti', []), (zalohaDenSplatnosti) => ({
			label: upperFirst(get(zalohaDenSplatnosti, 'nazov')),
			value: zalohaDenSplatnosti
		}))

		const zalohySumaValueBefore = (
			<NumericFormat
				thousandSeparator={' '}
				decimalScale={0}
				displayType={'text'}
				suffix={' €'}
				value={get(originalValue, 'zalohySuma')}
				defaultValue={''}
			/>
		)
		const zalohyCyklusValueBefore = get(originalValue, 'zalohyCyklus.nazov')
		const zalohyDenSplatnostiValueBefore = get(originalValue, 'zalohyDenSplatnosti.nazov')

		let zalohySumaValueAfter = null
		let zalohyCyklusValueAfter = null
		let zalohyDenSplatnostiValueAfter = null

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP) {
			/* Výška záloh */
			zalohySumaValueAfter = (
				<Field
					name={`${field}.zalohySuma`}
					component={this.debounceNumericInput}
					strict
					placeholder={this.formatValueVyskaZaloh(get(originalValue, 'zalohySuma'))}
					validate={this.validateZalohySuma}
					formatValue={(val) => (val > 0 ? this.formatValueVyskaZaloh(val) : '')}
					customParse={this.customParseVyskaZaloh}
					precision={0}
				/>
			)

			/* Cyklus záloh */
			zalohyCyklusValueAfter = (
				<Field
					name={`${field}.zalohyCyklus`}
					component={SelectField}
					options={zalohyCyklusOptions}
					validate={this.validateZalohyCyklus}
					compare={(option, val) => {
						return get(option, 'value.id') == get(val, 'id')
					}}
					classNamePrefix='react-select'
				/>
			)

			/* Deň splatnosti záloh */
			zalohyDenSplatnostiValueAfter = (
				<Field
					name={`${field}.zalohyDenSplatnosti`}
					component={SelectField}
					options={zalohyDenSplatnostiOptions}
					validate={this.validateZalohyDenSplatnosti}
					compare={(option, val) => {
						return get(option, 'value.id') == get(val, 'id')
					}}
					classNamePrefix='react-select'
				/>
			)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			/* Výška záloh */
			const zalohySumaFormatted = (
				<NumericFormat thousandSeparator={' '} decimalScale={0} displayType={'text'} suffix={' €'} value={get(value, 'zalohySuma')} defaultValue={''} />
			)
			zalohySumaValueAfter =
				columnsCount == COLUMNS_COUNT.THREE
					? formatFormValue(get(value, 'zalohySuma'), get(originalValue, 'zalohySuma'), zalohySumaFormatted)
					: zalohySumaFormatted

			/* Cyklus záloh */
			const zalohyCyklusFormatted = upperFirst(get(value, 'zalohyCyklus.nazov'))
			zalohyCyklusValueAfter =
				columnsCount == COLUMNS_COUNT.THREE
					? formatFormValue(get(value, 'zalohyCyklus.id'), get(originalValue, 'zalohyCyklus.id'), zalohyCyklusFormatted)
					: zalohyCyklusFormatted

			/* Deň splatnosti záloh */
			const zalohyDenSplatnostiFormatted = upperFirst(get(value, 'zalohyDenSplatnosti.nazov'))
			zalohyDenSplatnostiValueAfter =
				columnsCount == COLUMNS_COUNT.THREE
					? formatFormValue(get(value, 'zalohyDenSplatnosti.id'), get(originalValue, 'zalohyDenSplatnosti.id'), zalohyDenSplatnostiFormatted)
					: zalohyDenSplatnostiFormatted
		}

		const zalohyNeznizovatSumuElement = React.createElement(BooleanField, {
			...this.props,
			field: `${field}.zalohyNeznizovatSumu`,
			label: t('translation:Common.Neznižovať výšku záloh'),
			value: get(value, 'zalohyNeznizovatSumu'),
			originalValue: get(originalValue, 'zalohyNeznizovatSumu'),
			nested: true
		})

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('translation:Common.Cyklus záloh')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td>{zalohyCyklusValueBefore}</td>}
							<td>{zalohyCyklusValueAfter}</td>
						</tr>
						<tr>
							<td>
								<strong>{t('translation:Common.Výška záloh')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td>{zalohySumaValueBefore}</td>}
							<td>
								{zalohySumaValueAfter}
								<div style={{ marginTop: '10px' }}>
									{t('translation:Common.Ročná suma záloh')}:{' '}
									{calculateYearAdvancePayment(get(value, 'zalohyCyklus.id'), get(value, 'zalohySuma', 0))}
								</div>
							</td>
						</tr>
						{zalohyNeznizovatSumuElement}
						<tr>
							<td>
								<strong>{t('translation:Common.Deň splatnosti záloh')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td>{zalohyDenSplatnostiValueBefore}</td>}
							<td>{zalohyDenSplatnostiValueAfter}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	ciselniky: get(state, 'ciselniky.data')
})

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(ZalohyField)
