import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { find } from 'lodash'

class OptionsPickerField extends React.Component {
	static propTypes = {
		input: PropTypes.shape({
			name: PropTypes.string.isRequired,
			value: PropTypes.any
		}).isRequired,
		meta: PropTypes.shape({
			touched: PropTypes.bool.isRequired,
			error: PropTypes.string
		}).isRequired,
		selectedOptionId: PropTypes.number,
		options: PropTypes.array.isRequired,
		type: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
		disabled: PropTypes.bool,
		onlyValue: PropTypes.bool,
		compare: PropTypes.func
	}

	constructor(props) {
		super(props)
	}

	handleClick(selectedOption) {
		const { input, onlyValue } = this.props

		if (onlyValue) {
			input.onChange(selectedOption.value)
		} else {
			input.onChange(selectedOption)
		}
	}

	checkSelectedOption(option) {
		const { input, selectedOptionId, compare } = this.props
		if (compare !== undefined) {
			return compare(option, input.value)
		}
		return selectedOptionId === option.id
	}

	render() {
		const { input, meta, options, t, disabled } = this.props

		const { touched, error } = meta || {}

		if (!options || options.length === 0) {
			return <div>{t('atoms:OptionsPickerField.Žiadne položky')}</div>
		}

		return (
			<>
				<div data-name={input.name} className='picker-field'>
					<div className='picker-field-options'>
						{options.map((option) => {
							return (
								<div key={option.id}>
									<button
										type='button'
										className={cx('picker-field-option', {
											'picker-field-option-selected': this.checkSelectedOption(option)
										})}
										onClick={() => this.handleClick(option)}
										disabled={disabled}
									>
										{option.label}
									</button>
								</div>
							)
						})}
					</div>
					<div className='text-danger'>{touched ? error : ''}</div>
				</div>
			</>
		)
	}
}

export default compose(withTranslation('atoms'))(OptionsPickerField)
