import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { get, isEmpty } from 'lodash'

// atoms
import { SelectField } from '../../../atoms'
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { formatAddress, miestoSpotrebyAdresaDoplnokFormat } from '../../../utils/address'

class MiestoSpotrebyVyberField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		form: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.string,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		formValues: PropTypes.shape(),
		originalValue: PropTypes.shape(),
		miestaSpotreby: PropTypes.array.isRequired,
		povinny: PropTypes.bool.isRequired,
		vstupny: PropTypes.bool.isRequired
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { povinny, t } = this.props

		if (povinny && !value) {
			return t('translation:Common.validate.Zvoľte adresu miesta spotreby')
		}
	}

	getOptions = () => {
		const { miestaSpotreby } = this.props

		const options = miestaSpotreby?.map((miestoSpotreby) => {
			return {
				label: `${formatAddress(get(miestoSpotreby, 'adresa'))}, ${miestoSpotrebyAdresaDoplnokFormat(miestoSpotreby)}`,
				value: { cislo: miestoSpotreby.cislo }
			}
		})

		if (isEmpty(options)) {
			return []
		}

		return options
	}

	getSelectedAddress = (miestaSpotrebyOptions, selectedMiestaSpotreby) => {
		const filteredMiestoSpotreby = miestaSpotrebyOptions.find((miestoSpotrebyOption) => miestoSpotrebyOption.cislo == selectedMiestaSpotreby?.cislo)
		return get(filteredMiestoSpotreby, 'label')
	}

	renderLabel = (editMode, label) => {
		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP || editMode == EDIT_MODE.CREATE) {
			return label
		}
		if (editMode == EDIT_MODE.CONFIRM) {
			return 'Miesto spotreby'
		}
	}

	render() {
		const { field, label, t, editMode, columnsCount = COLUMNS_COUNT.THREE, formValues } = this.props

		const miestaSpotrebyOptions = this.getOptions()

		const valueBefore = <td />
		let valueAfter = null

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP || editMode == EDIT_MODE.CREATE) {
			valueAfter = (
				<td>
					<Field
						name={field}
						component={SelectField}
						options={miestaSpotrebyOptions}
						validate={this.validate}
						placeholder={t('translation:Common.Vyberte adresu')}
						isDisabled={isEmpty(miestaSpotrebyOptions)}
						classNamePrefix='react-select'
						compare={(option, val) => {
							return get(option, 'value.cislo') === get(val, 'cislo')
						}}
					/>
				</td>
			)
		} else if (editMode == EDIT_MODE.CONFIRM) {
			valueAfter = (
				<td>
					<strong>{this.getSelectedAddress(miestaSpotrebyOptions, formValues?.[field])}</strong>
				</td>
			)
		}

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{this.renderLabel(editMode, label)}</strong>
							</td>
							{columnsCount === COLUMNS_COUNT.THREE && valueBefore}
							{valueAfter}
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}
export default compose(withTranslation('components'))(MiestoSpotrebyVyberField)
