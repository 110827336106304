import React from 'react'
import ReactQuill from 'react-quill'
import PropTypes from 'prop-types'
import cx from 'classnames'

import 'react-quill/dist/quill.snow.css'

const Bold = ReactQuill.Quill.import('formats/bold')
Bold.tagName = 'b'
ReactQuill.Quill.register(Bold, true)

const Italic = ReactQuill.Quill.import('formats/italic')
Italic.tagName = 'i'
ReactQuill.Quill.register(Italic, true)

const Inline = ReactQuill.Quill.import('blots/inline')

class PlaceholderBlot extends Inline {
	static blotName = 'placeholder'

	static className = 'editor-placeholder'

	static tagName = 'span'

	/* static create(value) {
		let node = super.create(value)
		node.setAttribute('contenteditable', false)
		return node
	} */

	static formats() {
		return true
	}
}
ReactQuill.Quill.register(PlaceholderBlot, true)

function QuillEditorField({ input: { name, onChange, value }, meta: { touched, error }, label, disabled, ...props }) {
	return (
		<div className={cx('input-wrapper', { 'has-error': touched && error })}>
			{label && <label>{label}</label>}
			<ReactQuill
				{...props}
				name={name}
				value={value}
				ref={props.reference}
				onChange={(value, delta, source, editor) => {
					onChange(value)
					props.handleChange && props.handleChange(value, delta, source, editor)
				}}
				disabled={disabled}
			/>
			<div className='text-danger'>{touched ? error : ''}</div>
		</div>
	)
}

QuillEditorField.propTypes = {
	input: PropTypes.shape({
		name: PropTypes.string.isRequired,
		value: PropTypes.any,
		onChange: PropTypes.func.isRequired
	}).isRequired,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	reference: PropTypes.func,
	handleChange: PropTypes.func,
	label: PropTypes.string,
	disabled: PropTypes.bool
}

QuillEditorField.defaultProps = {
	input: {
		value: null
	}
}

export default QuillEditorField
