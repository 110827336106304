import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { map, get, upperFirst, isEmpty } from 'lodash'

// atoms
import OptionsPickerField from '../../../atoms/OptionsPickerField'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { formatFormValue } from '../../../utils/form'

class ProduktField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		produkty: PropTypes.arrayOf(
			PropTypes.shape({
				nazov: PropTypes.string.isRequired,
				kod: PropTypes.string.isRequired
			})
		),
		povinny: PropTypes.bool.isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape()
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { povinny, produkty, t } = this.props

		if (povinny && !value) {
			return t('translation:Common.validate.Produkt je povinný')
		}

		if (!value) return

		const existInProdukty = produkty.some((produkt) => produkt.kod === value.kod)
		if (existInProdukty === false) {
			return t('translation:Common.validate.Je vybraný chybný produkt')
		}
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, value, originalValue, produkty, t } = this.props

		const produktyOptions = map(produkty, (produkt) => ({
			label: upperFirst(get(produkt, 'nazov')),
			value: produkt
		}))

		const valueBefore = upperFirst(get(originalValue, 'nazov'))
		let valueAfter = null

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP) {
			valueAfter = (
				<Field
					name={field}
					component={OptionsPickerField}
					options={produktyOptions}
					validate={this.validate}
					compare={(option, val) => {
						return get(option, 'value.kod') == get(val, 'kod')
					}}
					disabled={isEmpty(produktyOptions)}
					onlyValue
				/>
			)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			valueAfter =
				columnsCount == COLUMNS_COUNT.THREE
					? formatFormValue(get(value, 'nazov'), get(originalValue, 'nazov'), upperFirst(get(value, 'nazov')))
					: upperFirst(get(value, 'nazov'))
		}

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('translation:Common.Produkt')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
							<td>{valueAfter}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

export default compose(withTranslation('components'))(ProduktField)
