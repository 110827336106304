import dayjs from 'dayjs'
import 'dayjs/locale/sk'
import duration from 'dayjs/plugin/duration'

dayjs.locale('sk')
dayjs.extend(duration)

export const INVALID_DATE_FORMAT = 'Neplatný Dátum'
export const DEFAULT_DATE_FORMAT = 'D. MMM YYYY'

/**
 * @param Date date
 * @return string
 *
 * Returns formatted date
 */
export function formatDate(date, placeholder = INVALID_DATE_FORMAT, format = DEFAULT_DATE_FORMAT) {
	if (!date) {
		return placeholder
	}
	if (!dayjs(date).isValid()) {
		return INVALID_DATE_FORMAT
	}
	return dayjs(date).format(format)
}

/**
 * @param Date date
 * @return string
 *
 * Returns formatted date with time
 */
export function formatDateWithTime(date) {
	if (!dayjs(date).isValid()) {
		return INVALID_DATE_FORMAT
	}
	return dayjs(date).format('DD.MM.YYYY HH:mm')
}

/**
 * @param Date a
 * @param Date b
 * @return int number of days between dates
 *
 * Returns number of days between two dates
 */
export function dateDiff(a, b) {
	const start = dayjs(a)
	const end = dayjs(b)
	if (!start.isValid() || !end.isValid()) {
		return INVALID_DATE_FORMAT
	}
	return Math.abs(dayjs.duration(end.diff(start)).asDays())
}

/**
 * @param string|Date birthDate
 * @return int age
 *
 * Returns age from birthdate, if some date is not valid returns INVALID_DATE_FORMAT
 */
export function getAge(birthDate, deathDate = null) {
	if (!dayjs(birthDate).isValid()) {
		return INVALID_DATE_FORMAT
	}

	if (dayjs(deathDate).isValid()) {
		return dayjs(deathDate).diff(birthDate, 'year')
	}

	return dayjs().diff(birthDate, 'year')
}

/**
 * @param Date|string a
 * @param Date|string b
 * @return int
 *
 * Returns ABS value of minutes between two dates, if some date is not valid returns INVALID_DATE_FORMAT
 */
export function minutesBetween(a, b) {
	const start = dayjs(a)
	const end = dayjs(b)
	if (!start.isValid() || !end.isValid()) {
		return INVALID_DATE_FORMAT
	}
	return Math.abs(dayjs.duration(end.diff(start)).asMinutes())
}
