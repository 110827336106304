export const SIGN_PDF_DONE = 'SIGN_PDF_DONE'
export const SIGN_PDF_FAIL = 'SIGN_PDF_FAIL'
export const SIGN_PDF_START = 'SIGN_PDF_START'
export const SIGN_PDF_CLEAR = 'SIGN_PDF_CLEAR'
export const SIGN_PDF_CANCEL = 'SIGN_PDF_CANCEL'

export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const SET_ADDITIONAL_NOTIFICATION = 'SET_ADDITIONAL_NOTIFICATION'
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'

export const TEMPLATE_PDF_LOAD_START = 'TEMPLATE_PDF_LOAD_START'
export const TEMPLATE_PDF_LOAD_DONE = 'TEMPLATE_PDF_LOAD_DONE'
export const TEMPLATE_PDF_LOAD_FAIL = 'TEMPLATE_PDF_LOAD_FAIL'

export const TEMPLATES_PDF_LOAD_START = 'TEMPLATES_PDF_LOAD_START'
export const TEMPLATES_PDF_LOAD_DONE = 'TEMPLATES_PDF_LOAD_DONE'
export const TEMPLATES_PDF_LOAD_FAIL = 'TEMPLATES_PDF_LOAD_FAIL'
