import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

function CommonContainer(props) {
	const { className, title, content, children, modal } = props
	return (
		<div className={cx('inner-box', className)}>
			<h4>{title}</h4>
			<table className='content-table bordered' cellSpacing='0' style={{ minHeight: '100px' }}>
				<tbody>
					{content}
					{children}
				</tbody>
			</table>
			{modal}
		</div>
	)
}

CommonContainer.defaultProps = {
	title: 'Výstupné dokumenty'
}

CommonContainer.propTypes = {
	content: PropTypes.shape(),
	modal: PropTypes.shape(),
	title: PropTypes.string
}

export default CommonContainer
