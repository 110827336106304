import React from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { filter, forEach, get, isEqual, map, set, some, omit } from 'lodash'
import { change, touch } from 'redux-form'

// components
import { COLUMNS_COUNT, EDIT_MODE, getGenericUkonConfig } from '../../../containers/GenericUkon/genericUkonConfig'
import UndefinedField from '../fields/UndefinedField'
import ElementLoading from '../../ElementLoading'
import { GENERIC_FIELDS_KEY } from '../../../containers/AnonymnyVseobecnyUkon/AnonymnyVseobecnyUkonPage'

// utils
import { FORMS, UKONY_CISELNIK } from '../../../utils/enums'
import { postReq } from '../../../utils/request'

class GenericFields extends React.Component {
	static propTypes = {
		parentIndex: PropTypes.number.isRequired,
		parentFieldName: PropTypes.string.isRequired,
		selectedUkon: PropTypes.shape().isRequired,
		ponuka: PropTypes.shape(),
		interakcia: PropTypes.shape(),
		auth: PropTypes.shape(),
		tracking: PropTypes.shape(),
		formValues: PropTypes.shape(),
		change: PropTypes.shape()
	}

	constructor(props) {
		super(props)

		this.state = {
			isLoading: false,
			attributes: {
				supportedAttributes: [],
				unsupportedAttributes: [],
				supportedEmptyLookupAttributes: []
			}
		}
	}

	prepareAttributes = (attributes, ukon) => {
		const genericUkonConfig = getGenericUkonConfig(ukon?.typ)
		const fieldsConfig = genericUkonConfig.fields

		const supportedAttributes = map(
			filter(attributes, (attribute) => {
				const config = fieldsConfig[get(attribute, 'typ')]
				return config && !get(config, 'readonly')
			}),
			(attribute) => ({
				...attribute,
				...fieldsConfig[get(attribute, 'typ')]
			})
		)
		const unsupportedAttributes = map(
			filter(attributes, (attribute) => {
				return !fieldsConfig[get(attribute, 'typ')]
			})
		)
		const supportedEmptyLookupAttributes = filter(supportedAttributes, (attribute) => {
			const attributeData = get(ukon, get(attribute, 'cesta'))
			return get(attribute, 'cesta') && get(attribute, 'vstupny') && (attributeData == null || attributeData == undefined)
		})

		return {
			supportedAttributes,
			unsupportedAttributes,
			supportedEmptyLookupAttributes
		}
	}

	schvalovacieKriteriaHandler = (schvalovacieKriteriaNew) => {
		const { schvalovacieKriteria } = this.state

		if (!isEqual(schvalovacieKriteria, schvalovacieKriteriaNew)) {
			this.setState({
				schvalovacieKriteria: schvalovacieKriteriaNew
			})
		}
	}

	formatDataForUkon = (values) => {
		const { auth } = this.props
		const { attributes } = this.state

		let data = {
			data: {
				...get(values, 'data', {})
			},
			id: get(values, 'id', undefined),
			typ: { id: UKONY_CISELNIK.ANONYMNY_VSEOBECNY_UKON },
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual')
		}

		forEach(attributes, (attribute) => {
			if (get(attribute, 'format')) {
				const formattedValue = attribute.format(this.props, get(values, get(attribute, 'cesta')))
				set(data, get(attribute, 'cesta'), formattedValue)
			}
		})

		return data
	}

	prepareDraftRecalc = async () => {
		const { dispatch, formValues, parentIndex } = this.props
		const { selectedPonuka } = this.state

		// key of form field in fieldArray which is represents key of rendered "sub form"
		const fieldKey = `${GENERIC_FIELDS_KEY}[${parentIndex}]`
		// "sub form" actual values
		const fieldValues = get(formValues, fieldKey)

		this.setState({
			...this.state,
			isLoading: true
		})

		try {
			const body = this.formatDataForUkon(fieldValues)

			const res = await postReq(`/api/v2/ukony/draft-prepare`, null, body)
			const ponukaRecalc = get(res, 'response.content')

			const ukon = get(ponukaRecalc, 'ukon', {})

			const { supportedAttributes, unsupportedAttributes, supportedEmptyLookupAttributes } = this.prepareAttributes(
				get(ponukaRecalc, 'atributy', []),
				ukon
			)

			// update "sub form" field values
			forEach(supportedAttributes, (attribute) => {
				if (!get(attribute, 'cesta')) {
					return
				}

				const attributeKey = `${fieldKey}.${get(attribute, 'cesta')}`
				dispatch(touch(FORMS.GENERIC_UKON_VSEOBECNY_ANONYMNY, attributeKey))

				if (!get(ukon, get(attribute, 'cesta'))) {
					return
				}
				const attributeValue = get(ukon, get(attribute, 'cesta'))
				// check if recalc change previous "sub form" field value
				if (!isEqual(fieldValues?.[get(attribute, 'cesta')], attributeValue)) {
					dispatch(change(FORMS.GENERIC_UKON_VSEOBECNY_ANONYMNY, attributeKey, attributeValue, null))
				}
			})

			// NOTE: adding ukon id
			dispatch(change(FORMS.GENERIC_UKON_VSEOBECNY_ANONYMNY, `${fieldKey}.id`, get(ukon, 'id')))

			this.setState({
				selectedPonuka: {
					...selectedPonuka,
					ukon: { ...get(ponukaRecalc, 'ukon'), id: get(ponukaRecalc, 'ukon.id') },
					atributy: get(ponukaRecalc, 'atributy')
				},
				attributes: {
					supportedAttributes,
					unsupportedAttributes,
					supportedEmptyLookupAttributes
				},
				isLoading: false
			})
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
			this.setState({
				...this.state,
				isLoading: false
			})
		}
	}

	componentDidMount() {
		this.prepareDraftRecalc()
	}

	componentDidUpdate(prevProps) {
		const { dispatch, parentIndex, ponuka, selectedUkon, formValues } = this.props
		const { attributes } = this.state

		// NOTE: key of form field in fieldArray which is represents key of rendered "sub form"
		const fieldKey = `${GENERIC_FIELDS_KEY}[${parentIndex}]`
		// NOTE: "sub form" actual values
		const fieldValues = get(formValues, fieldKey)
		// NOTE: "sub form" previous values
		const prevFieldValues = get(prevProps?.formValues, fieldKey)

		const supportedAtributes = get(attributes, 'supportedAttributes')

		if (prevFieldValues && !isEqual(fieldValues, prevFieldValues)) {
			const changedRecalcAttribute = some(supportedAtributes, (attribute) => {
				if (!get(attribute, 'cesta')) {
					return
				}
				if (isEqual(get(fieldValues, get(attribute, 'cesta')), get(prevFieldValues, get(attribute, 'cesta')))) {
					return
				}
				if (get(attribute, 'recalc') && !get(attribute, 'vstupny')) {
					return attribute
				}
			})

			if (changedRecalcAttribute) {
				this.prepareDraftRecalc()
			}
		}
	}

	render() {
		const { parentIndex, parentFieldName, formValues } = this.props
		const { attributes, isLoading } = this.state

		if (isLoading) {
			return (
				<div style={{ minHeight: '600px' }}>
					<ElementLoading />
				</div>
			)
		}

		const editMode = EDIT_MODE.EDIT

		const renderSupportedAttributes = map(attributes.supportedAttributes, (attribute, index) => {
			const fieldComponent = get(attribute, 'fieldComponent')
			const fieldPath = get(attribute, 'cesta')
			const fieldName = `${parentFieldName}.${get(attribute, 'cesta')}`

			return React.createElement(fieldComponent, {
				key: `generic-form-field-${index}`,
				name: fieldName,
				form: FORMS.GENERIC_UKON_VSEOBECNY_ANONYMNY,
				field: fieldName || null,
				parentFieldName,
				editMode,
				columnsCount: COLUMNS_COUNT.TWO,
				value: fieldPath ? get(formValues, `${GENERIC_FIELDS_KEY}[${parentIndex}].[${fieldPath}]`) : null,
				formValues,
				scenarioOption: get(confirm, 'scenarioOption'),
				...attribute
			})
		})

		const renderUnsupportedFields = map(attributes.unsupportedAttributes, (attribute, index) => {
			const fieldPath = get(attribute, 'cesta')
			const fieldName = `${parentFieldName}.${get(attribute, 'cesta')}`

			return React.createElement(UndefinedField, {
				key: `generic-form-unsupported-field-${index}`,
				field: fieldPath || null,
				name: fieldName,
				editMode,
				columnsCount: COLUMNS_COUNT.THREE,
				value: fieldPath ? get(formValues, `${GENERIC_FIELDS_KEY}[${parentIndex}].[${fieldPath}]`) : null,
				...attribute
			})
		})

		return (
			<div>
				{renderSupportedAttributes}
				{renderUnsupportedFields}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	ponuka: state.ponuka,
	tracking: state.tracking,
	interakcia: state.interakcie
})

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(GenericFields)
