import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { find, throttle } from 'lodash'
import cx from 'classnames'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

const SELECT_ALL_OPTION_VALUE = 'select_all'

class SelectField extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		input: PropTypes.shape({
			name: PropTypes.string.isRequired,
			value: PropTypes.any,
			onChange: PropTypes.func.isRequired
		}).isRequired,
		meta: PropTypes.shape({
			touched: PropTypes.bool,
			error: PropTypes.string
		}).isRequired,
		showErrorOnUntouch: PropTypes.bool,
		customStyles: PropTypes.shape(),
		compare: PropTypes.func,
		selectAllOption: PropTypes.bool
	}

	state = {
		open: false
	}

	constructor(props) {
		super(props)
		this.selectRef = React.createRef()
	}

	handleMenuOpen = () => {
		this.setState({
			open: true
		})
		if (this.props.onMenuOpen) {
			this.props.onMenuOpen()
		}
	}

	handleBlur = () => {
		const focusedElement = document.activeElement
		if (focusedElement && !focusedElement.className.includes('react-select')) {
			this.setState({
				open: false
			})
		} else {
			throttle(this.selectRef.current.focus(), 150)
		}
	}

	handleChangeSimple = (option) => {
		this.setState({
			open: false
		})
		const val = option ? option.value : null
		this.props.input.onChange(val)
	}

	handleChangeMultiple = (val) => {
		const { selectAllOption, options, input } = this.props
		this.setState({
			open: false
		})
		let values = val || []
		if (selectAllOption && val.some((selectedOpt) => selectedOpt.value === SELECT_ALL_OPTION_VALUE)) {
			values = [...options]
		}
		input.onChange([...values])
	}

	render() {
		const {
			t,
			input,
			meta: { touched, error },
			showErrorOnUntouch,
			customStyles,
			compare,
			options,
			selectAllOption,
			...props
		} = this.props
		const { open } = this.state

		const parsedOption = [...options]
		if (selectAllOption) {
			parsedOption.push({ label: t('atoms:Všetky'), value: SELECT_ALL_OPTION_VALUE })
		}

		let val = input.value !== undefined ? input.value : null
		let handleChange = this.handleChangeSimple

		if (props.isMulti) {
			handleChange = this.handleChangeMultiple
			if (!val) {
				val = []
			}
		} else {
			val = find(parsedOption, (o) => {
				if (compare !== undefined) {
					return compare(o, val)
				}
				return o.value === val
			})
		}

		return (
			<div data-name={input.name} className={cx('select-wrapper', { 'has-error': (touched || showErrorOnUntouch) && error })}>
				<Select
					{...props}
					options={parsedOption}
					value={val || null}
					onChange={(e) => handleChange(e)}
					noOptionsMessage={() => 'Nič sa nenašlo.'}
					placeholder={props.placeholder || 'Zvoľte možnosť'}
					// NOTE: next lines are for IE hot fix https://github.com/JedWatson/react-select/issues/1020
					onMenuOpen={this.handleMenuOpen}
					menuIsOpen={open}
					onBlur={this.handleBlur}
					ref={this.selectRef}
					styles={customStyles}
				/>
				<div className='text-danger'>{touched || showErrorOnUntouch ? error : ''}</div>
			</div>
		)
	}
}
export default compose(withTranslation('atoms'), connect(null))(SelectField)
