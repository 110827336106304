import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { get, map } from 'lodash'

// atoms
import { RadioGroupField } from '../../../atoms'

// utils
import { formatFazyPocetLabel } from '../../../utils/zmluvneVztahy'
import { formatFormValue } from '../../../utils/form'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

class FazyPocetField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		fazy: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				nazov: PropTypes.string.isRequired
			})
		),
		povinny: PropTypes.bool.isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape()
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	formatFazyPocetOptions = () => {
		const { ciselniky, t } = this.props

		return map(get(ciselniky, 'fazyPocet', []), (fazyPocet) => ({
			label: formatFazyPocetLabel(fazyPocet, t),
			value: fazyPocet
		}))
	}

	validate = (value) => {
		const { povinny, t } = this.props

		if (povinny && !value) {
			return t('translation:Common.validate.Počet fáz je povinný')
		}
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, value, originalValue, t } = this.props

		const valueBefore = formatFazyPocetLabel(originalValue, t)
		let valueAfter = null

		const fazyPocetOptions = this.formatFazyPocetOptions()

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP) {
			valueAfter = (
				<Field
					name={field}
					component={RadioGroupField}
					options={fazyPocetOptions}
					compare={(option, val) => {
						return get(option, 'value.id') == get(val, 'id')
					}}
					validate={this.validate}
				/>
			)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			valueAfter =
				columnsCount == COLUMNS_COUNT.THREE
					? formatFormValue(get(value, 'id'), get(originalValue, 'id'), formatFazyPocetLabel(value, t))
					: formatFazyPocetLabel(value, t)
		}

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('translation:Common.Počet fáz')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
							<td>{valueAfter}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		ciselniky: get(state, 'ciselniky.data')
	}
}

export default compose(withTranslation('components'), connect(mapStateToProps))(FazyPocetField)
