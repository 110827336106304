import React from 'react'
import { change, Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { find, forEach, get, isEmpty, map, unescape, upperFirst } from 'lodash'

// components
import DefaultModal from '../../Modals/DefaultModal'

// atoms
import { TextInputField } from '../../../atoms'
import Select from '../../../atoms/BasicSelect'
import QuillEditorField from '../../../atoms/QuillEditorField'

// config
import { EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { FORMS } from '../../../utils/enums'
import { formatHTML } from '../../../utils/systemoveListy'

function findPlaceholderOccurencies(html) {
	const regex = /\[\[.[^([[)]+\]\]/gi
	let result
	const indices = []
	// eslint-disable-next-line no-cond-assign
	while ((result = regex.exec(html))) {
		indices.push({
			from: result.index,
			to: result.index + get(result, '0.length', 0),
			length: get(result, '0.length', 0)
		})
	}
	return indices
}

function normalizePlaceholderOccurence(indices, quillRef, keyIndex, offset = 0) {
	const occurence = find(indices, (index) => {
		return index.from <= keyIndex && index.to >= keyIndex
	})
	if (occurence) {
		quillRef.deleteText(occurence.from, occurence.length - offset)
		return occurence
	}
	return false
}

class OdpovedTextField extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		formValues: PropTypes.shape(),
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		placeholder: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		povinny: PropTypes.bool.isRequired,
		sablony: PropTypes.shape({
			id: PropTypes.string.isRequired,
			nazov: PropTypes.string.isRequired
		})
	}

	_defaultKeyBindings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+ľščťžýáíé=úä(/ň§ô)!"-.,_:?>'

	_numpadKeyCodes = []

	initKeyBindings = () => {
		// NOTE: 96 - 111 are codes for NumPad
		for (let i = 96; i < 112; i++) {
			this._numpadKeyCodes.push(i)
		}

		return {
			delete: {
				// NOTE: 46 is code for DELETE button
				key: 46,
				collapsed: true,
				handler: (range) => {
					if (!normalizePlaceholderOccurence(this.state.odovedTextPlaceholderIndices, this.state.quillRef, range.index)) {
						return true
					}
				}
			},
			delete_collapsed: {
				// NOTE: 46 is code for DELETE button
				key: 46,
				collapsed: false,
				handler: (range) => {
					if (!normalizePlaceholderOccurence(this.state.odovedTextPlaceholderIndices, this.state.quillRef, range.index)) {
						return true
					}
				}
			},
			backspace: {
				// NOTE: 8 is code for BACKSPACE button
				key: 8,
				collapsed: true,
				handler: (range) => {
					if (!normalizePlaceholderOccurence(this.state.odovedTextPlaceholderIndices, this.state.quillRef, range.index)) {
						return true
					}
				}
			},
			backspace_collapsed: {
				// NOTE: 8 is code for BACKSPACE button
				key: 8,
				collapsed: false,
				handler: (range) => {
					if (!normalizePlaceholderOccurence(this.state.odovedTextPlaceholderIndices, this.state.quillRef, range.index)) {
						return true
					}
				}
			}
		}
	}

	constructor(props) {
		super(props)

		this.state = {
			ukonVstupOptions: [],
			quillRef: null,
			odpovedTextHTML: null,
			odovedTextPlaceholderIndices: [],
			bindings: this.initKeyBindings(),
			sablonaInConfirm: null,
			sablony: [],
			sablonyOptions: [],
			sablonaChangeDialog: false
		}
	}

	componentDidMount() {
		this._mounted = true
		const { t, sablony } = this.props

		this.setState({
			sablony,
			sablonyOptions: [
				{
					label: t('translation:SystemoveListyUkon.Prázdna šablóna'),
					value: null
				},
				...map(sablony, (sablona) => ({
					label: get(sablona, 'nazov'),
					value: get(sablona, 'id')
				}))
			]
		})
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.quillRef && this.state.quillRef) {
			forEach([...this._defaultKeyBindings, ...this._numpadKeyCodes], (key) => {
				this.state.quillRef.keyboard.addBinding({ key }, (range) => {
					normalizePlaceholderOccurence(this.state.odovedTextPlaceholderIndices, this.state.quillRef, range.index)
					return true
				})
			})
			// Combination Shift + <Numpad Key> is not required
			forEach([...this._defaultKeyBindings], (key) => {
				this.state.quillRef.keyboard.addBinding(
					{
						key,
						shiftKey: true
					},
					(range) => {
						normalizePlaceholderOccurence(this.state.odovedTextPlaceholderIndices, this.state.quillRef, range.index)
						return true
					}
				)
			})
		}
	}

	sablonaChanged = () => {
		const { dispatch, cesta } = this.props
		const { sablonaInConfirm, sablony } = this.state

		const sablona = find(sablony, (sablona) => get(sablona, 'id') === sablonaInConfirm)
		const odpovedText = unescape(get(sablona, 'obsah')).replace(/\[\[/g, '<span class="editor-placeholder">[[').replace(/]]/g, ']]</span>')
		dispatch(change(FORMS.GENERIC_UKON, 'data.sablona', sablonaInConfirm))
		dispatch(change(FORMS.GENERIC_UKON, cesta, odpovedText || null))

		this.setState({
			sablonaInConfirm: null,
			sablonaChangeDialog: false
		})
	}

	handleChangeSablona = (selectedSablona) => {
		const { formValues } = this.props

		if (get(formValues, 'data.sablona') != selectedSablona) {
			this.setState({
				sablonaInConfirm: selectedSablona,
				sablonaChangeDialog: true
			})
		}
	}

	validate = (value) => {
		const { t, povinny } = this.props

		if (povinny && !value) {
			return t('translation:SystemoveListyUkon.validate.Text odpovede je povinný')
		}

		if (povinny && isEmpty(formatHTML(value))) {
			return t('translation:SystemoveListyUkon.validate.Text odpovede je povinný')
		}

		if (value.indexOf('editor-placeholder') >= 0) {
			return t('translation:SystemoveListyUkon.validate.Nevyplnili ste všetky povinné informácie v texte odpovede')
		}
	}

	render() {
		const { t, field, editMode, formValues } = this.props
		const { bindings, quillRef, sablonyOptions, sablonaChangeDialog } = this.state

		if (editMode == EDIT_MODE.DETAIL) {
			return null
		}

		let content = null
		const activeSablona = get(formValues, 'data.sablona')

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.CREATE) {
			content = (
				<>
					<tr>
						<td>
							<strong>{t('translation:SystemoveListyUkon.Značka')}</strong>
						</td>
						<td>
							<Field name='data.znacka' component={TextInputField} placeholder={t('translation:SystemoveListyUkon.Značka')} />
						</td>
					</tr>
					<tr>
						<td>
							<strong>{t('translation:SystemoveListyUkon.Šablóna')}</strong>
						</td>
						<td>
							<Select
								name={'data.sablona'}
								classNamePrefix='react-select'
								value={find(sablonyOptions, (option) => option.value == activeSablona)}
								options={sablonyOptions}
								onChange={(sablona) => this.handleChangeSablona(get(sablona, 'value'))}
								isSearchable={true}
								isDisabled={isEmpty(sablonyOptions)}
								isClearable={false}
							/>
						</td>
					</tr>
					<tr>
						<td colSpan='2'>
							<div style={{ paddingBottom: '10px' }}>
								<strong>{t('translation:SystemoveListyUkon.Text odpovede')}</strong>
							</div>
							<Field
								name={field}
								placeholder={t('translation:SystemoveListyUkon.Zadajte text odpoveďe')}
								component={QuillEditorField}
								modules={{
									toolbar: [['bold', 'italic', 'underline', { list: 'bullet' }]],
									keyboard: { bindings }
								}}
								formats={['bold', 'italic', 'underline', 'list', 'bullet', 'indent', 'color', 'background', 'placeholder']}
								reference={(ref) => {
									if (!quillRef && ref) {
										this.setState({
											quillRef: ref.getEditor()
										})
									}
								}}
								validate={this.validate}
								handleChange={(html, delta, source, editor) => {
									const text = editor.getText()
									this.setState({
										odpovedTextHTML: text,
										odovedTextPlaceholderIndices: findPlaceholderOccurencies(text)
									})
								}}
								theme='snow'
							/>
						</td>
					</tr>
				</>
			)
		} else if (editMode == EDIT_MODE.CONFIRM) {
			content = null
		}

		return (
			<>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>{content}</tbody>
				</table>
				{sablonaChangeDialog && (
					<DefaultModal
						modalTitle={t('translation:SystemoveListyUkon.Zmena šablóny')}
						modalContent={t(
							'translation:SystemoveListyUkon.Zmenou šablóny budú odstránený aj doteraz zadaný text odpovede Naozaj chcete pokračovať?'
						)}
						leftButton={{
							onClick: () => {
								this.setState({
									sablonaChangeDialog: false
								})
							},
							text: t('translation:Common.Zrušiť'),
							outline: true,
							color: 'red'
						}}
						rightButton={{
							onClick: this.sablonaChanged,
							text: t('translation:Common.Potvrdiť'),
							color: 'blue'
						}}
						visible
					/>
				)}
			</>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('containers'), connect(null, mapDispatchToProps))(OdpovedTextField)
