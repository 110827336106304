import React from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Field, change } from 'redux-form'
import dayjs from 'dayjs'

// atoms
import TimeRangeField from '../../../atoms/TimeRangeField'
import { CheckboxField } from '../../../atoms'

// utils
import { FORMS } from '../../../utils/enums'

import GenericFields from './GenericFields'

export const UKON_TIME_RANGE_KEY = 'trvanie'
export const PRIMARNY_UKON_KEY = 'primaryUkonIndex'

class GenericUkonForm extends React.Component {
	static propTypes = {
		formValues: PropTypes.shape(),
		ukonData: PropTypes.shape()
	}

	constructor(props) {
		super(props)
	}

	onChangePrimarnyUkonIndex = (index) => {
		const { dispatch, formValues } = this.props

		if (formValues[PRIMARNY_UKON_KEY] === index) {
			dispatch(change(FORMS.GENERIC_UKON_VSEOBECNY_ANONYMNY, PRIMARNY_UKON_KEY, null))
		} else {
			dispatch(change(FORMS.GENERIC_UKON_VSEOBECNY_ANONYMNY, PRIMARNY_UKON_KEY, index))
		}
	}

	render() {
		const { t, fields, formValues, ukonData } = this.props

		return (
			<>
				<table className='content-table bordered padded top-aligned'>
					<thead>
						<tr>
							<th style={{ width: '280px' }}>{t('translation:AnonymnyVseobecnyUkon.Čas úkonu')}</th>
							<th>{t('translation:AnonymnyVseobecnyUkon.Typ anonymného všeobecného úkonu')}</th>
							<th style={{ width: '40px' }} />
						</tr>
					</thead>
					<tbody>
						<>
							{fields.map((member, index) => {
								const timeRangeFieldName = `${member}.${UKON_TIME_RANGE_KEY}`

								return (
									<>
										<tr key={`generic-field-row-${index}`}>
											<td>
												<div style={{ marginBottom: '16px' }}>
													<Field
														name={timeRangeFieldName}
														component={TimeRangeField}
														startRange={dayjs().set('hours', 0).startOf('minute')}
														endRange={dayjs().set('hours', 23).endOf('minute')}
														minuteIncrement={1}
														use24Hours={true}
														placeholder={t('translation:Common.Zvoľte čas')}
														showErrorOnUntouch
													/>
												</div>
												<div>
													<Field
														component={CheckboxField}
														label={t('translation:AnonymnyVseobecnyUkon.Označiť ako primárny úkon')}
														input={{
															value: index === formValues[PRIMARNY_UKON_KEY],
															onChange: () => this.onChangePrimarnyUkonIndex(index)
														}}
													/>
												</div>
											</td>
											<td>
												<div className='row'>
													<div className='col-12'>
														<GenericFields parentIndex={index} parentFieldName={member} formValues={formValues} />
													</div>
												</div>
											</td>
											<td>{fields?.length > 1 && <div className='remove-button' onClick={() => fields.remove(index)} />}</td>
										</tr>
									</>
								)
							})}
						</>
					</tbody>
				</table>
				<div className='text-center'>
					<div
						className='button with-icon'
						data-type='outline'
						onClick={() =>
							fields.push({
								data: ukonData,
								trvanie: {
									startTime: dayjs().startOf('minute').toISOString(),
									endTime: dayjs().startOf('minute').toISOString()
								}
							})
						}
						data-icon='plus'
						data-color='blue'
					>
						{t('translation:AnonymnyVseobecnyUkon.Pridať úkon')}
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie
})

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(GenericUkonForm)
