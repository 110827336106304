import React from 'react'
import { compose, bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { initialize, reset, destroy, reduxForm, FieldArray, getFormValues } from 'redux-form'
import { get, map } from 'lodash'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { Tooltip } from 'react-tippy'
import { EDIT_MODE } from '../GenericUkon/genericUkonConfig'

// layouts
import Header from '../Layout/Header'

// components
import DefaultModal from '../../components/Modals/DefaultModal'
import ElementLoading from '../../components/ElementLoading'
import GenericUkonForm, { PRIMARNY_UKON_KEY } from '../../components/GenericUkon/AnonymnyVseobecnyUkon/GenericUkonForm'
import UkoncenieExternejIntegracie from '../../components/Modals/UkoncenieExternejIntegracie'
import { ConditionalWrapper } from '../../components/ConditionalWrapper'

// actions
import * as DataActions from '../../actions/DataActions'
import * as ExternalIntegrationsActions from '../../actions/ExternalIntegrationsActions'

// utils
import { postReq } from '../../utils/request'
import { FORMS, UKONY_CISELNIK } from '../../utils/enums'
import { INDEX } from '../../utils/routes'
import { history } from '../../utils/history'
import { createFormInitValues } from '../../utils/form'
import { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { getExterneIDs } from '../../utils/externalIntegrations'
import { getAccessToken } from '../../utils/auth'
import { changePositiveIndexToNegative } from '../../utils/formatData'

import validate from './validateForm'
import { recognizeMethodologicalGuideline } from '../GenericUkon/guidelineConfig'

export const GENERIC_FIELDS_KEY = 'anonymneVseobecneUkony'

const FORM_STATE = {
	PENDING: 'pending',
	LOADING: 'loading',
	SUCCESS: 'success',
	ERROR: 'error'
}

class AnonymnyVseobecnyUkonPage extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		auth: PropTypes.shape().isRequired,
		dataActions: PropTypes.shape().isRequired,
		invalid: PropTypes.bool,
		formValues: PropTypes.shape(),
		t: PropTypes.func.isRequired,
		externalIntegrations: PropTypes.shape()
	}

	_mounted = false

	constructor(props) {
		super(props)

		this.state = {
			formState: FORM_STATE.PENDING,
			modalText: null,
			showExternalIntegrationModal: false
		}
	}

	componentDidMount() {
		this._mounted = true

		const { dispatch, dataActions, location } = this.props

		dataActions.registerLeavePageModal()

		const ukon = get(location, 'anonymnyUkon.ukon')

		dispatch(
			initialize(
				FORMS.GENERIC_UKON_VSEOBECNY_ANONYMNY,
				createFormInitValues({
					[GENERIC_FIELDS_KEY]: [
						{
							data: get(ukon, 'data', null),
							trvanie: {
								startTime: dayjs().startOf('minute').toISOString(),
								endTime: dayjs().startOf('minute').toISOString()
							},
							vstup: get(ukon, 'vstup', null)
						}
					]
				}),
				true
			)
		)
		dispatch(reset(FORMS.GENERIC_UKON_VSEOBECNY_ANONYMNY))
	}

	componentWillUnmount() {
		const { dispatch, dataActions } = this.props

		dispatch(destroy(FORMS.GENERIC_UKON_VSEOBECNY_ANONYMNY))

		dataActions.unregisterLeavePageModal()

		this._mounted = false
	}

	formatDataForUkon = (values, shouldConnectExternalIntegration) => {
		const { auth, externalIntegrations } = this.props

		let primarnyUkonId
		const primarnyUkonIndex = values[PRIMARNY_UKON_KEY]

		const ukony = map(get(values, GENERIC_FIELDS_KEY, []), (ukon, index) => {
			if (primarnyUkonIndex === index) {
				primarnyUkonId = get(ukon, 'id')
			}
			return {
				id: get(ukon, 'id'),
				typ: { id: UKONY_CISELNIK.ANONYMNY_VSEOBECNY_UKON },
				riesitel: get(auth, 'user.id'),
				kanal: get(auth, 'businessChannel.actual'),
				trvanie: dayjs(get(ukon, 'trvanie.endTime')).diff(dayjs(get(ukon, 'trvanie.startTime')), 'millisecond'),
				vstup: get(ukon, 'vstup', null),
				poznamka: get(ukon, 'poznamka', null),
				data: get(ukon, 'data', null)
			}
		})

		let externeIDsForRequest = []
		if (shouldConnectExternalIntegration) {
			externeIDsForRequest = getExterneIDs(externalIntegrations)
		}

		return { ukony, externeIDs: externeIDsForRequest, primarnyUkonId }
	}

	fallbackCopyTextToClipboard = (text) => {
		const textArea = document.createElement('textarea')
		textArea.value = text
		textArea.style.position = 'fixed' // avoid scrolling to bottom
		document.body.appendChild(textArea)
		textArea.focus()
		textArea.select()

		document.execCommand('copy')
		document.body.removeChild(textArea)
	}

	copyTextToClipboard = async (text) => {
		if (!navigator.clipboard) {
			this.fallbackCopyTextToClipboard(text)
			return
		}

		try {
			await navigator.clipboard.writeText(text)
		} catch (error) {
			// Catch Document is not focused error
		}
	}

	submitUkon = async (shouldConnectExternalIntegration) => {
		const { dataActions, t, formValues } = this.props

		if (this._mounted) {
			this.setState({
				formState: FORM_STATE.LOADING
			})
		}

		try {
			const body = this.formatDataForUkon(formValues, shouldConnectExternalIntegration)

			const interakcia = await postReq('/api/v2/ukony/spustit-zoznam', null, body)

			const ukony = get(interakcia, 'response.content.ukony', '-')

			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				let modalText = t('translation:Common.Úkon bol úspešne odoslaný pod číslom', { cislo: get(ukony, '[0].id', '-') })
				if (ukony.length > 1) {
					let cisla = ''
					ukony.forEach((ukon) => {
						cisla = `${cisla} ${ukon.id}`
					})
					modalText = t('translation:Common.Úkony boli úspešne odoslané pod číslami', { cisla })
				}
				this.setState({
					modalText,
					formState: FORM_STATE.SUCCESS
				})
				this.copyTextToClipboard(get(interakcia, 'response.obsah.ukony[0].id'))
			}
		} catch (e) {
			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					modalText: t('translation:Common.Počas odosielania úkonu nastala chyba'),
					formState: FORM_STATE.ERROR
				})
			}
		}
	}

	getDokoncitButtonState() {
		const { formValues, invalid, t } = this.props

		if (!formValues) {
			return { isButtonDisabled: true }
		}

		let isPrimaryUkonInvalid = false
		let tooltipText = ''

		if (formValues[PRIMARNY_UKON_KEY] == null) {
			isPrimaryUkonInvalid = true
			tooltipText = t('translation:AnonymnyVseobecnyUkon.Vyberte primárny úkon')
		}

		const isButtonDisabled = invalid || isPrimaryUkonInvalid

		return { isButtonDisabled, tooltipText }
	}

	handleDokoncitButton = () => {
		const { externalIntegrations } = this.props
		const { liveAgentId, finesseId, call250Id } = externalIntegrations

		// Ak existuje nejaká externá integrácia, zistíme ako chce používateľ ukončiť interakciu
		if (liveAgentId || finesseId || call250Id) {
			this.setState({ showExternalIntegrationModal: true })
			return
		}

		// Ak nie je žiadna externá integrácia, rovno ukončíme interakciu
		this.submitUkon(false)
	}

	// Uloží úkon a ukončí interakciu bez prepojenia na externú integráciu
	// Nevymaže externú integráciu zo session storagu
	endInteractionScenarA = async () => {
		this.setState({ showExternalIntegrationModal: false })
		this.submitUkon(false)
	}

	// Uloží úkon a ukončí interakciu s prepojením na externú integráciu
	// Nevymaže externú integráciu zo session storagu
	endInteractionScenarB = async () => {
		this.setState({ showExternalIntegrationModal: false })
		this.submitUkon(true)
	}

	// Uloží úkon a ukončí interakciu s prepojením na externú integráciu
	// Vymaže externú integráciu zo session storagu
	endInteractionScenarC = async () => {
		const { externalIntegrationsActions } = this.props
		this.setState({ showExternalIntegrationModal: false })

		this.submitUkon(true)
		externalIntegrationsActions.clearAllExternalSessions()
	}

	confirmModal = () => {
		const { formState } = this.state

		if (this._mounted) {
			if (formState === FORM_STATE.SUCCESS) {
				history.replace(INDEX)
			} else {
				this.setState({
					formState: FORM_STATE.PENDING,
					modalText: null
				})
			}
		}
	}

	commonContentContainer = (content) => {
		return (
			<div style={{ backgroundColor: '#F4F6F9', minHeight: '100%' }}>
				<Header />
				{content}
			</div>
		)
	}

	render() {
		const { ciselniky, invalid, t, formValues, location } = this.props
		const { formState, modalText, showExternalIntegrationModal } = this.state

		if (formState === FORM_STATE.LOADING || get(ciselniky, 'isLoading')) {
			return this.commonContentContainer(<ElementLoading />)
		}

		const editMode = EDIT_MODE.EDIT
		const { tooltipText, isButtonDisabled } = this.getDokoncitButtonState()

		const ukon = get(location, 'anonymnyUkon.ukon')

		const methodologicalGuidelineLink = recognizeMethodologicalGuideline({ typ: { id: UKONY_CISELNIK.ANONYMNY_VSEOBECNY_UKON } })

		return this.commonContentContainer(
			<>
				{modalText && (
					<DefaultModal
						modalTitle={formState === FORM_STATE.SUCCESS ? t('translation:Common.Odoslané') : t('translation:Common.Chyba')}
						modalContent={modalText}
						leftButton={{
							onClick: this.confirmModal,
							text: t('translation:Common.Zavrieť'),
							color: formState === FORM_STATE.SUCCESS ? 'green' : 'red'
						}}
						visible
					/>
				)}

				{showExternalIntegrationModal && (
					<UkoncenieExternejIntegracie
						visible={showExternalIntegrationModal}
						onEnd={this.endInteractionScenarC}
						onContinue={this.endInteractionScenarB}
						onContinueWithoutLinkId={this.endInteractionScenarA}
					/>
				)}

				{formState === FORM_STATE.PENDING && (
					<>
						<div className='page-subheader'>
							<button onClick={() => history.push(INDEX)} type='button' className='button' data-type='back-button' data-color='blue'>
								{t('translation:Common.Späť')}
							</button>
							<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
								<div className='subheader-title'>{t('translation:AnonymnyVseobecnyUkon.Anonymný všeobecný úkon')}</div>
								<a href={methodologicalGuidelineLink} className='guideline-icon' target='_blank' rel='noopener noreferrer' />
							</div>
							<Link to={INDEX} className='button' data-type='outline' data-color='red' style={{ marginLeft: 'auto' }}>
								{t('translation:Common.Zrušiť')}
							</Link>
							<ConditionalWrapper
								condition={tooltipText}
								wrapper={(children) => (
									<Tooltip html={<span>{tooltipText}</span>} position='bottom' trigger='mouseenter' theme='light'>
										{children}
									</Tooltip>
								)}
							>
								<button
									className={cx('button', { disabled: isButtonDisabled })}
									disabled={isButtonDisabled}
									type='submit'
									onClick={this.handleDokoncitButton}
									data-color='blue'
								>
									{t('translation:Common.Dokončiť')}
								</button>
							</ConditionalWrapper>
						</div>
						<div className='content-wrapper'>
							<div className='container' style={{ marginTop: '20px', marginBottom: '20px' }}>
								<div className='box'>
									<div className='box-content'>
										<FieldArray
											ukonData={get(ukon, 'data', null)}
											name={GENERIC_FIELDS_KEY}
											props={{ formValues, editMode }}
											component={GenericUkonForm}
										/>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</>
		)
	}
}

const form = reduxForm({
	form: FORMS.GENERIC_UKON_VSEOBECNY_ANONYMNY,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(AnonymnyVseobecnyUkonPage)

const mapStateToProps = (state) => ({
	auth: get(state, 'auth'),
	externalIntegrations: get(state, 'externalIntegrations'),
	formValues: getFormValues(FORMS.GENERIC_UKON_VSEOBECNY_ANONYMNY)(state)
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	dataActions: bindActionCreators(DataActions, dispatch),
	externalIntegrationsActions: bindActionCreators(ExternalIntegrationsActions, dispatch)
})

export default compose(withTranslation('containers'), connect(mapStateToProps, mapDispatchToProps), withPermissions([PERMISSIONS.UKON_ANONYM]))(form)
