import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, propTypes } from 'redux-form'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { get, find, upperFirst, omit, isEqual, forEach } from 'lodash'
import { Tooltip } from 'react-tippy'
import { compose } from 'redux'

import validate from './validateForm'

// utils
import { formatAddress } from '../../utils/address'
import { formatFormValue } from '../../utils/form'
import { FORMS } from '../../utils/enums'
import { formatDate } from '../../utils/date'

// components
import WrapperPodpisovanieDokumentov from '../PodpisovanieDokumentov/WrapperPodpisovanieDokumentov'
import PaymentMethodField from '../GenericUkon/fields/ZmluvnyUcet/PaymentMethodField'
import EfakturaEmailsField from '../GenericUkon/fields/ZmluvnyUcet/EfakturaEmailsField'

import { EDIT_MODE, COLUMNS_COUNT } from '../../containers/GenericUkon/genericUkonConfig'
import { areEquals } from '../../utils/suhlas'

class ZmluvnyUcetEditConfirm extends React.Component {
	static propTypes = {
		...propTypes,
		isNCB: PropTypes.bool.isRequired,
		originalValues: PropTypes.shape().isRequired,
		formTitle: PropTypes.string.isRequired,
		onSubmit: PropTypes.func.isRequired,
		onBackClick: PropTypes.func.isRequired,
		onCancelClick: PropTypes.func.isRequired,
		scenarioOption: PropTypes.shape({
			kanal: PropTypes.number.isRequired,
			scenarios: PropTypes.array.isRequired,
			notificationRequire: PropTypes.bool.isRequired,
			notificationTypes: PropTypes.array.isRequired
		})
	}

	requireSignatureAndNotification = () => {
		const { originalValues, formValues } = this.props

		if (
			areEquals(get(originalValues, 'suhlasEplatba'), get(formValues, 'suhlasEplatba')) &&
			areEquals(get(originalValues, 'suhlasEpreplatky'), get(formValues, 'suhlasEpreplatky')) &&
			areEquals(get(originalValues, 'suhlasEfaktura'), get(formValues, 'suhlasEfaktura'))
		) {
			return true
		}

		const omittedProps = [
			'suhlasEplatba',
			'suhlasEpreplatky',
			'suhlasEfaktura',
			'podpisMiesto',
			'splnomocnenec',
			'ukonVstup',
			'dokumenty',
			'poznamka',
			'datumPrijatiaZiadosti',
			'INIT_TIMESTAMP',
			'showEditAdresaOdoslanePlatby'
		]

		const previousFormValues = omit(originalValues, [
			...omittedProps,
			'adresaDoslePlatbySection',
			'adresaDoslePlatbySectionInherit',
			'adresaFakturacieSection',
			'adresaFakturacieSectionInherit',
			'adresaOdoslanePlatbySection',
			'adresaOdoslanePlatbySectionInherit',
			'adresaUpominaciaSection',
			'adresaUpominaciaSectionInherit'
		])
		const previous = {}
		forEach(Object.keys(previousFormValues), (key) => {
			previous[key] = get(originalValues, key, null)
		})

		const currentFormValues = omit(formValues, [...omittedProps])
		const current = {}
		forEach(Object.keys(currentFormValues), (key) => {
			current[key] = get(formValues, key, null)
		})

		return !isEqual(previous, current)
	}

	render() {
		const {
			handleSubmit,
			formValues,
			originalValues,
			formTitle,
			onBackClick,
			onCancelClick,
			addresses,
			ciselniky,
			t,
			isNCB,
			scenarioOption,
			isDisabledNotification,
			isDisabledSignedPdf,
			podpisovanieDokumentov
		} = this.props

		const ukonVstup = find(get(ciselniky, 'ukonVstup', []), (vstup) => get(vstup, 'id') == get(formValues, 'ukonVstup'))
		const fakturacnaPerioda = find(
			get(ciselniky, 'fakturacnaPerioda', []),
			(fakturacnaPerioda) => get(fakturacnaPerioda, 'id') == get(formValues, 'fakturacnaPerioda')
		)

		const showEditAdresaOdoslanePlatby = get(formValues, 'showEditAdresaOdoslanePlatby')
		const adresaOdoslanePlatby = find(addresses, (address) => get(address, 'id') == get(formValues, 'adresaOdoslanePlatby.id'))
		const adresaFakturacie = find(addresses, (address) => get(address, 'id') == get(formValues, 'adresaFakturacie.id'))

		const disabledBtn = (
			<button className='button' type='submit' disabled data-color='blue' style={{ marginLeft: '20px' }}>
				{t('translation:Common.Dokončiť')}
			</button>
		)

		const requiredSignatureAndNotification = this.requireSignatureAndNotification()

		let submitBtn = null
		if (!requiredSignatureAndNotification) {
			submitBtn = (
				<button className='button pull-right' type='submit' data-color='blue' style={{ marginLeft: '20px' }}>
					{t('translation:Common.Dokončiť')}
				</button>
			)
		} else if (get(podpisovanieDokumentov, 'templatePdf.isLoading')) {
			submitBtn = (
				<Tooltip
					html={<span>{t('translation:Common.Prebieha načítavanie dokumentu pre podpisovanie')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (isDisabledSignedPdf) {
			submitBtn = (
				<Tooltip html={<span>{t('translation:Common.Dokument musí byť podpísaný')}</span>} position='left' trigger='mouseenter' theme='light'>
					{disabledBtn}
				</Tooltip>
			)
		} else if (!isDisabledSignedPdf && isDisabledNotification) {
			submitBtn = (
				<Tooltip
					html={<span>{t('translation:Common.Na dokončenie úkonu je potrebné nastaviť notifikáciu')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					<button className='button' type='submit' disabled data-color='blue' style={{ marginLeft: '20px' }}>
						{t('translation:Common.Dokončiť')}
					</button>
				</Tooltip>
			)
		} else if (!isDisabledNotification && !isDisabledSignedPdf) {
			submitBtn = (
				<button className='button pull-right' type='submit' data-color='blue' style={{ marginLeft: '20px' }}>
					{t('translation:Common.Dokončiť')}
				</button>
			)
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>{submitBtn}</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{t('translation:Common.Aktuálne hodnoty')}</th>
										<th>{t('translation:Common.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Vstup do úkonu')}</strong>
										</td>
										<td>{get(originalValues, 'ukonVstup.nazov')}</td>
										<td>{upperFirst(get(ukonVstup, 'nazov'))}</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Splnomocnenec')}</strong>
										</td>
										<td />
										<td>{get(formValues, 'splnomocnenec')}</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Miesto podpisu úkonu')}</strong>
										</td>
										<td>{get(originalValues, 'podpisMiesto')}</td>
										<td>{get(formValues, 'podpisMiesto')}</td>
									</tr>
									<tr>
										<td>
											<strong>{t('components:EditContactConfirm.Dátum prijatia žiadosti')}</strong>
										</td>
										<td />
										<td>{formatDate(get(formValues, 'datumPrijatiaZiadosti'), null, 'DD.MM.YYYY')}</td>
									</tr>
								</tbody>
							</table>
							{isNCB && (
								<div className='inner-box'>
									<table className='content-table padded bordered' cellSpacing='0'>
										<tbody>
											<tr>
												<td>
													<strong>{t('translation:ZmluvneUcty.Frekvencia platby')}</strong>
												</td>
												<td>{upperFirst(get(originalValues, 'fakturacnaPerioda.nazov')) || '-'}</td>
												<td>
													{formatFormValue(
														get(formValues, 'fakturacnaPerioda'),
														get(originalValues, 'fakturacnaPerioda.id'),
														upperFirst(get(fakturacnaPerioda, 'nazov'))
													) || '-'}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							)}
							<EfakturaEmailsField
								field='eFakturaEmaily'
								editMode={EDIT_MODE.CONFIRM}
								columnsCount={COLUMNS_COUNT.THREE}
								originalValues={originalValues}
								formValues={formValues}
							/>
							<PaymentMethodField
								field='doslePlatbySposob'
								paymentMethod='doslePlatbySposob'
								editMode={EDIT_MODE.CONFIRM}
								columnsCount={COLUMNS_COUNT.THREE}
								originalValues={originalValues}
								formValues={formValues}
							/>
							<PaymentMethodField
								field='odoslanePlatbySposob'
								paymentMethod='odoslanePlatbySposob'
								editMode={EDIT_MODE.CONFIRM}
								columnsCount={COLUMNS_COUNT.THREE}
								originalValues={originalValues}
								formValues={formValues}
							/>
							<div className='inner-box'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<div>
													<strong>{t('translation:ZmluvneUcty.Fakturačná adresa')}</strong>
												</div>
												{(get(adresaFakturacie, 'doRukMeno') || get(originalValues, 'adresaFakturacie.doRukMeno')) && (
													<>
														<br />
														<div>
															<strong>{t('translation:Common.Do rúk')}</strong>
														</div>
													</>
												)}
											</td>
											<td>
												<div>
													<div style={{ position: 'relative', paddingRight: '20px' }}>
														{get(originalValues, 'adresaFakturacieSection')}
														{get(originalValues, 'adresaFakturacieSectionInherit') && (
															<Tooltip
																html={
																	<>
																		<span>{t('translation:Common.Odvodená adresa z')}</span>
																		<br />
																		<span>
																			<strong>{get(originalValues, 'adresaFakturacieSectionInherit')}</strong>
																		</span>
																	</>
																}
																position='bottom'
																trigger='mouseenter'
																theme='light'
															>
																<div className='info-icon' />
															</Tooltip>
														)}
													</div>
												</div>
												{(get(adresaFakturacie, 'doRukMeno') || get(originalValues, 'adresaFakturacie.doRukMeno')) && (
													<>
														<br />
														<div>{get(originalValues, 'adresaFakturacie.doRukMeno')}&nbsp;</div>
													</>
												)}
											</td>
											<td>
												<div>
													{formatFormValue(formatAddress(adresaFakturacie, false), get(originalValues, 'adresaFakturacieSection'))}
												</div>
												{(get(adresaFakturacie, 'doRukMeno') || get(originalValues, 'adresaFakturacie.doRukMeno')) && (
													<>
														<br />
														<div>
															{formatFormValue(
																get(adresaFakturacie, 'doRukMeno'),
																get(originalValues, 'adresaFakturacie.doRukMeno')
															)}
															&nbsp;
														</div>
													</>
												)}
											</td>
										</tr>
										<tr>
											<td>
												<div>
													<strong>{t('translation:ZmluvneUcty.Adresa pre doručovanie poštových poukážok')}</strong>
												</div>
												{(get(showEditAdresaOdoslanePlatby ? adresaOdoslanePlatby : adresaFakturacie, 'doRukMeno') ||
													get(originalValues, 'adresaOdoslanePlatby.doRukMeno')) && (
													<>
														<br />
														<div>
															<strong>{t('translation:Common.Do rúk')}</strong>
														</div>
													</>
												)}
											</td>
											<td>
												<div>
													<div style={{ position: 'relative', paddingRight: '20px' }}>
														{get(originalValues, 'adresaOdoslanePlatbySection')}
														{get(originalValues, 'adresaOdoslanePlatbySectionInherit') && (
															<Tooltip
																html={
																	<>
																		<span>{t('translation:Common.Odvodená adresa z')}</span>
																		<br />
																		<span>
																			<strong>{get(originalValues, 'adresaOdoslanePlatbySectionInherit')}</strong>
																		</span>
																	</>
																}
																position='bottom'
																trigger='mouseenter'
																theme='light'
															>
																<div className='info-icon' />
															</Tooltip>
														)}
													</div>
												</div>
												{(get(showEditAdresaOdoslanePlatby ? adresaOdoslanePlatby : adresaFakturacie, 'doRukMeno') ||
													get(originalValues, 'adresaOdoslanePlatby.doRukMeno')) && (
													<>
														<br />
														<div>{get(originalValues, 'adresaOdoslanePlatby.doRukMeno')}&nbsp;</div>
													</>
												)}
											</td>
											<td>
												<div>
													{formatFormValue(
														formatAddress(showEditAdresaOdoslanePlatby ? adresaOdoslanePlatby : adresaFakturacie, false),
														get(originalValues, 'adresaOdoslanePlatbySection')
													)}
												</div>
												{(get(originalValues, 'adresaOdoslanePlatby.doRukMeno') ||
													get(showEditAdresaOdoslanePlatby ? adresaOdoslanePlatby : adresaFakturacie, 'doRukMeno')) && (
													<>
														<br />
														<div>
															{formatFormValue(
																get(showEditAdresaOdoslanePlatby ? adresaOdoslanePlatby : adresaFakturacie, 'doRukMeno'),
																get(originalValues, 'adresaOdoslanePlatby.doRukMeno')
															)}
															&nbsp;
														</div>
													</>
												)}
											</td>
										</tr>
										<tr>
											<td>
												<div>
													<strong>{t('translation:ZmluvneUcty.Adresa pre zasielanie upomienok')}</strong>
												</div>
												{(get(adresaFakturacie, 'doRukMeno') || get(originalValues, 'adresaUpominacia.doRukMeno')) && (
													<>
														<br />
														<div>
															<strong>{t('translation:Common.Do rúk')}</strong>
														</div>
													</>
												)}
											</td>
											<td>
												<div>
													<div style={{ position: 'relative', paddingRight: '20px' }}>
														{get(originalValues, 'adresaUpominaciaSection')}
														{get(originalValues, 'adresaUpominaciaSectionInherit') && (
															<Tooltip
																html={
																	<>
																		<span>{t('translation:Common.Odvodená adresa z')}</span>
																		<br />
																		<span>
																			<strong>{get(originalValues, 'adresaUpominaciaSectionInherit')}</strong>
																		</span>
																	</>
																}
																position='bottom'
																trigger='mouseenter'
																theme='light'
															>
																<div className='info-icon' />
															</Tooltip>
														)}
													</div>
												</div>
												{(get(adresaFakturacie, 'doRukMeno') || get(originalValues, 'adresaUpominacia.doRukMeno')) && (
													<>
														<br />
														<div>{get(originalValues, 'adresaUpominacia.doRukMeno')}&nbsp;</div>
													</>
												)}
											</td>
											<td>
												<div>
													{formatFormValue(formatAddress(adresaFakturacie, false), get(originalValues, 'adresaUpominaciaSection'))}
												</div>
												{(get(adresaFakturacie, 'doRukMeno') || get(originalValues, 'adresaUpominacia.doRukMeno')) && (
													<>
														<br />
														<div>
															{formatFormValue(
																get(adresaFakturacie, 'doRukMeno'),
																get(originalValues, 'adresaUpominacia.doRukMeno')
															)}
															&nbsp;
														</div>
													</>
												)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className='inner-box'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:ZmluvneUcty.Pripomienka platby')}</strong>
											</td>
											<td>
												{get(originalValues, 'neposielatPripomienkuPlatby')
													? t('translation:Common.Neaktívne')
													: t('translation:Common.Aktívne')}
											</td>
											<td>
												{formatFormValue(
													get(formValues, 'neposielatPripomienkuPlatby')
														? t('translation:Common.Neaktívne')
														: t('translation:Common.Aktívne'),
													get(originalValues, 'neposielatPripomienkuPlatby')
														? t('translation:Common.Neaktívne')
														: t('translation:Common.Aktívne')
												)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Poznámka')}</strong>
										</td>
										<td />
										<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
											<div className='input-wrapper'>
												<textarea
													disabled='disabled'
													rows='5'
													className='form-control static'
													style={{ backgroundColor: 'white' }}
													value={get(formValues, 'poznamka')}
												/>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							{requiredSignatureAndNotification && <WrapperPodpisovanieDokumentov scenarioOption={scenarioOption} />}
							{!requiredSignatureAndNotification && (
								<div className='inner-box'>
									<strong>{t('translation:Common.Validácia údajov')}</strong>
									<div className='message-contact-fields-validation'>
										<p>
											{t(
												'translation:Common.Validácia údajov Úkon nebude generovať žiadne notifikácie klientovi Pre pokračovanie stlačte tlačidlo'
											)}
											<strong> {t('translation:Common.Dokončiť')}</strong>
										</p>
										{submitBtn}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.UPRAVA_ZMLUVNEHO_UCTU,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	validate
})(ZmluvnyUcetEditConfirm)

const mapStateToProps = (state) => {
	return {
		podpisovanieDokumentov: get(state, 'podpisovanieDokumentov')
	}
}

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
