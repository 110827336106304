import React from 'react'
import { withTranslation } from 'react-i18next'
import { isEmpty, upperFirst, get, find, map, uniqBy, filter, includes, replace, head } from 'lodash'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import cx from 'classnames'
import * as IBAN from 'iban'

// atoms
import ReasonSelector from '../../../../atoms/generics/ReasonSelector'
import SelectField from '../../../../atoms/SelectField'
import IBANFieldWrapper from '../../../../atoms/IBANField/IBANField'

// utils
import { createSuhlasDTO, formatOriginalSuhlas, formatSuhlas, isValidSuhlas } from '../../../../utils/suhlas'
import {
	SUHLAS_HODNOTA,
	SUHLAS_TYPE,
	PAYMENT_METHOD_TYPE,
	SPOSOB_PLATBY_DOSLA_PLATBA,
	SPOSOB_PLATBY_ODOSLANA_PLATBA,
	FORMS,
	DEFINICIA_TYP
} from '../../../../utils/enums'
import { EDIT_MODE, COLUMNS_COUNT } from '../../../../containers/GenericUkon/genericUkonConfig'
import { formatFormValue } from '../../../../utils/form'

const PROP_NAMES = {
	SUHLAS: 'SUHLAS',
	PAYMENT_METHOD: 'PAYMENT_METHOD',
	CISELNIKY_CODE: 'CISELNIKY_CODE',
	IBAN: 'IBAN',
	IBAN_FORM: 'IBAN_FORM',
	LABEL_KEY: 'LABEL_KEY',
	SUHLAS_TYPE: 'SUHLAS_TYPE'
}

class PaymentMethodField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		paymentMethod: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		t: PropTypes.shape(),
		originalValues: PropTypes.shape(),
		onChangeSuhlas: PropTypes.func,
		formValues: PropTypes.shape(),
		wrapIntoContainer: PropTypes.bool,
		onAddIBAN: PropTypes.func,
		ciselniky: PropTypes.shape(),
		cesCiselniky: PropTypes.shape(),
		nesuhlasyZu: PropTypes.shape(),
		nesuhlasyDefinicie: PropTypes.shape(),
		bankoveUcty: PropTypes.shape(),
		validate: PropTypes.func
	}

	constructor(props) {
		super(props)

		switch (props.paymentMethod) {
			case PAYMENT_METHOD_TYPE.INCOMING:
				this.state = {
					associatedProps: {
						[PROP_NAMES.SUHLAS]: 'suhlasEplatba',
						[PROP_NAMES.PAYMENT_METHOD]: SPOSOB_PLATBY_DOSLA_PLATBA,
						[PROP_NAMES.CISELNIKY_CODE]: 'doslaPlatbaSposob',
						[PROP_NAMES.IBAN]: 'doslePlatbyBankoveSpojenie',
						[PROP_NAMES.IBAN_FORM]: FORMS.IBAN_FIELD_DOSLE_PLATBY,
						[PROP_NAMES.LABEL_KEY]: 'translation:ZmluvneUcty.Prijaté platby',
						[PROP_NAMES.SUHLAS_TYPE]: SUHLAS_TYPE.INCOMING_PAYEMNT
					}
				}

				break
			case PAYMENT_METHOD_TYPE.OUTCOMING:
				this.state = {
					associatedProps: {
						[PROP_NAMES.SUHLAS]: 'suhlasEpreplatky',
						[PROP_NAMES.PAYMENT_METHOD]: SPOSOB_PLATBY_ODOSLANA_PLATBA,
						[PROP_NAMES.CISELNIKY_CODE]: 'odoslanaPlatbaSposob',
						[PROP_NAMES.IBAN]: 'odoslanePlatbyBankoveSpojenie',
						[PROP_NAMES.IBAN_FORM]: FORMS.IBAN_FIELD_ODOSLANE_PLATBY,
						[PROP_NAMES.LABEL_KEY]: 'translation:ZmluvneUcty.Odoslané platby',
						[PROP_NAMES.SUHLAS_TYPE]: SUHLAS_TYPE.OUTCOMING_PAYMENT
					}
				}

				break
			default:
				throw new Error(`Unsupported/missing PAYMENT_METHOD_TYPE for generic field`)
		}
	}

	componentDidUpdate(prevProps) {
		const { paymentMethod, formValues, onChangeSuhlas } = this.props

		const originalValue = get(prevProps, `formValues.${paymentMethod}`)
		const newValue = get(formValues, paymentMethod)

		const originalNonPreferredValue = this.getPropName(PROP_NAMES.PAYMENT_METHOD).POSTOVA_POUKAZKA == get(originalValue, 'id')
		const newNonPreferredValue = this.getPropName(PROP_NAMES.PAYMENT_METHOD).POSTOVA_POUKAZKA == get(newValue, 'id')

		if (originalNonPreferredValue != newNonPreferredValue) {
			if (newNonPreferredValue) {
				onChangeSuhlas(createSuhlasDTO(SUHLAS_HODNOTA.NAMIETKA, null))
			} else {
				onChangeSuhlas(createSuhlasDTO(SUHLAS_HODNOTA.SUHLAS, null))
			}
		}
	}

	getPropName = (propertyKey) => {
		if (!includes(Object.keys(PROP_NAMES), propertyKey)) {
			throw new Error(`Property ${propertyKey} is not mapped`)
		}

		const { associatedProps } = this.state

		return associatedProps[propertyKey]
	}

	getSposobPlatbyName = () => {
		const { ciselniky, formValues, paymentMethod } = this.props
		const sposobyPlatby = get(ciselniky, this.getPropName(PROP_NAMES.CISELNIKY_CODE), [])
		const sposob = find(sposobyPlatby, (sposob) => get(sposob, 'id') == get(formValues, `${paymentMethod}.id`))
		return get(sposob, 'nazov')
	}

	getReadonlyValue = (iban) => {
		const { paymentMethod, formValues } = this.props
		if (paymentMethod == PAYMENT_METHOD_TYPE.OUTCOMING) {
			if (get(formValues, 'doslePlatbySposob.id') == SPOSOB_PLATBY_DOSLA_PLATBA.INKASNY_PRIKAZ) {
				if (iban) {
					return IBAN.printFormat(get(formValues, `${this.getPropName(PROP_NAMES.IBAN)}.id`) || '') || '-'
				}
				const sposobPlatbyName = this.getSposobPlatbyName()
				return upperFirst(sposobPlatbyName || '-')
			}
		}

		return null
	}

	getEditModeContent = (originalNonPreferredValue, newNonPreferredValue, originalSuhlas, newSuhlas, originalValue) => {
		const { field, ciselniky, bankoveUcty, onAddIBAN, onChangeSuhlas, paymentMethod, validate, t, nesuhlasyZu, nesuhlasyDefinicie } = this.props

		const readonlyValue = this.getReadonlyValue()
		const readonlyIban = this.getReadonlyValue(true)
		const selectOptions = map(get(ciselniky, this.getPropName(PROP_NAMES.CISELNIKY_CODE), []), (sposob) => ({
			label: upperFirst(get(sposob, 'nazov')),
			value: sposob
		}))

		/* UC06 - Zmena sposobu platby */
		const accounts = uniqBy(
			filter(get(bankoveUcty, 'allData', []), (account) => !isEmpty(get(account, 'IBAN'))),
			'IBAN'
		)

		let ibanPropPath = `${this.getPropName(PROP_NAMES.IBAN)}`
		if (field !== paymentMethod) {
			ibanPropPath = replace(field, paymentMethod, ibanPropPath)
		}

		let reasonDovod = null
		if (newSuhlas && newSuhlas.hodnota == SUHLAS_HODNOTA.NAMIETKA && isValidSuhlas(newSuhlas)) {
			if (newSuhlas.nesuhlasDovodIny) {
				reasonDovod = newSuhlas.nesuhlasDovodIny
			} else if (newSuhlas.nesuhlasDovod && newSuhlas.nesuhlasDovod.id) {
				reasonDovod = newSuhlas.nesuhlasDovod.id
			}
		}

		const definiciaPaymentType = PAYMENT_METHOD_TYPE.INCOMING ? DEFINICIA_TYP.E_PLATBA : DEFINICIA_TYP.E_PREPLATKY

		const optionsData =
			nesuhlasyDefinicie?.[definiciaPaymentType]?.nesulasy ||
			head(nesuhlasyZu.data?.response?.definicie?.filter((definicia) => definicia.id === definiciaPaymentType))?.nesulasy

		const reasonOptions = map(optionsData, (nesuhlas) => {
			return {
				value: nesuhlas.id,
				label: nesuhlas.dovod
			}
		})

		return {
			valueBefore: (
				<>
					{upperFirst(get(originalValue, 'nazov'))}
					{originalNonPreferredValue && (
						<Tooltip html={<span>{t('translation:Common.Nedoporučené nastavenie')}</span>} position='bottom' trigger='mouseenter' theme='light'>
							<div className='info-icon' />
						</Tooltip>
					)}
					<p>{formatOriginalSuhlas(originalNonPreferredValue, originalSuhlas)}</p>
				</>
			),
			valueAfter: (
				<>
					{readonlyValue || (
						<Field
							name={field}
							component={SelectField}
							isSearchable={false}
							options={selectOptions}
							isDisabled={isEmpty(selectOptions)}
							classNamePrefix='react-select'
							compare={(option, value) => get(option, 'value.id') === value.id}
						/>
					)}
					{newNonPreferredValue && (
						<div style={{ marginTop: '5px' }}>
							<ReasonSelector
								selectOptions={reasonOptions}
								onChange={onChangeSuhlas}
								value={reasonDovod}
								reasonIsNotActual={newSuhlas && !isValidSuhlas(newSuhlas)}
							/>
						</div>
					)}
				</>
			),
			IBANAfter: (
				<>
					{readonlyIban || (
						<Field
							name={ibanPropPath}
							formName={this.getPropName(PROP_NAMES.IBAN_FORM)}
							isCreatable
							isClearable
							accounts={accounts}
							onNewAccount={onAddIBAN}
							component={IBANFieldWrapper}
							validate={validate}
						/>
					)}
				</>
			)
		}
	}

	getReadonlyContent = (originalNonPreferredValue, newNonPreferredValue, originalSuhlas, newSuhlas, originalValue) => {
		const { originalValues, formValues, cesCiselniky, t } = this.props

		const sposobPlatbyName = this.getSposobPlatbyName()

		// const formIbanPath = ibanPropSelector ? `${this.getPropName(PROP_NAMES.IBAN)}.${ibanPropSelector}` : this.getPropName(PROP_NAMES.IBAN)

		const newIBAN = get(formValues, `${this.getPropName(PROP_NAMES.IBAN)}.id`)

		return {
			valueBefore: (
				<>
					{upperFirst(get(originalValue, 'nazov') || '-')}
					<p>{formatOriginalSuhlas(originalNonPreferredValue, originalSuhlas)}</p>
				</>
			),
			valueAfter: (
				<>
					{formatFormValue(sposobPlatbyName, get(originalValue, 'nazov'), upperFirst(sposobPlatbyName || '-'))}
					<p>{newNonPreferredValue && formatSuhlas(newNonPreferredValue, newSuhlas, originalSuhlas, cesCiselniky?.nesuhlasDovod)}</p>
					{newNonPreferredValue && (
						<Tooltip html={<span>{t('translation:Common.Nedoporučené nastavenie')}</span>} position='bottom' trigger='mouseenter' theme='light'>
							<div className='info-icon' />
						</Tooltip>
					)}
				</>
			),
			IBANAfter: <>{formatFormValue(newIBAN, get(originalValues, `${this.getPropName(PROP_NAMES.IBAN)}.id`), IBAN.printFormat(newIBAN || '')) || '-'}</>
		}
	}

	render() {
		const { paymentMethod, editMode, columnsCount = COLUMNS_COUNT.THREE, formValues, originalValues, wrapIntoContainer = true, t } = this.props

		let valueBefore = null
		let valueAfter = null
		let IBANAfter = null

		const originalValue = get(originalValues, paymentMethod)
		const newValue = get(formValues, paymentMethod)

		const originalNonPreferredValue = this.getPropName(PROP_NAMES.PAYMENT_METHOD).POSTOVA_POUKAZKA == get(originalValue, 'id')
		const newNonPreferredValue = this.getPropName(PROP_NAMES.PAYMENT_METHOD).POSTOVA_POUKAZKA == get(newValue, 'id')

		if (columnsCount == COLUMNS_COUNT.THREE) {
			const originalSuhlas = get(originalValues, this.getPropName(PROP_NAMES.SUHLAS))
			const newSuhlas = get(formValues, this.getPropName(PROP_NAMES.SUHLAS))

			if (editMode == EDIT_MODE.EDIT) {
				const editContent = this.getEditModeContent(originalNonPreferredValue, newNonPreferredValue, originalSuhlas, newSuhlas, originalValue)
				valueBefore = editContent.valueBefore
				valueAfter = editContent.valueAfter
				IBANAfter = editContent.IBANAfter
			} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
				const readonlyContent = this.getReadonlyContent(originalNonPreferredValue, newNonPreferredValue, originalSuhlas, newSuhlas, originalValue)
				valueBefore = readonlyContent.valueBefore
				valueAfter = readonlyContent.valueAfter
				IBANAfter = readonlyContent.IBANAfter
			}
		}

		const content = (
			<>
				<tr>
					<td>
						<strong>{t('translation:ZmluvneUcty.Spôsob platby zmluvného účtu')}</strong>
					</td>
					{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
					<td>{valueAfter}</td>
				</tr>
				{!newNonPreferredValue && (
					<tr>
						<td>
							<strong>{t('translation:ZmluvneUcty.IBAN')}</strong>
						</td>
						{columnsCount == COLUMNS_COUNT.THREE && (
							<td>{IBAN.printFormat(get(originalValues, `${this.getPropName(PROP_NAMES.IBAN)}.id`) || '') || '-'}</td>
						)}
						<td>{IBANAfter}</td>
					</tr>
				)}
			</>
		)

		return wrapIntoContainer ? (
			<div className={cx('inner-box', { warning: newNonPreferredValue })}>
				<h4 className='uppercase' data-text-color='blue' style={{ margin: '10px' }}>
					{t(this.getPropName(PROP_NAMES.LABEL_KEY)) || ''}
				</h4>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>{content}</tbody>
				</table>
			</div>
		) : (
			<>
				<tr>
					<th className='uppercase' data-text-color='blue' style={{ margin: '10px', float: 'left' }}>
						{t(this.getPropName(PROP_NAMES.LABEL_KEY)) || ''}
					</th>
				</tr>
				{content}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	ciselniky: get(state, 'ciselniky.data'),
	cesCiselniky: get(state, 'cesCiselniky.data'),
	bankoveUcty: get(state, 'obchodnyPartner.bankoveUcty'),
	nesuhlasyZu: get(state, 'ukonEditZU.nesuhlasyZu')
})

export default compose(withTranslation('components'), connect(mapStateToProps, null))(PaymentMethodField)
