import { get, isEmpty } from 'lodash'

// utils
import { KOMODITA_TYP, UKONY_CISELNIK } from '../../utils/enums'
import { getAccessToken } from '../../utils/auth'

const METHODOLOGICAL_GUIDELINES = {
	VSEOBECNY_UKON_AKTIVNY: `/api/v0/pomocnik/vseobecnyUkon?accessToken=${getAccessToken()}`,
	VSEOBECNY_UKON_PASIVNY: `/api/v0/pomocnik/vseobecnyUkonPasivny?accessToken=${getAccessToken()}`,
	PREPIS_ZMLUVY_EE: `/api/v0/pomocnik/prepisZmluvyEE?accessToken=${getAccessToken()}`,
	PREPIS_ZMLUVY_GAS: `/api/v0/pomocnik/prepisZmluvyGAS?accessToken=${getAccessToken()}`,
	NOVY_ODBER_EE: `/api/v0/pomocnik/novyOdberEE?accessToken=${getAccessToken()}`,
	NOVY_ODBER_EE_PREPIS_DVOJICKA: `/api/v0/pomocnik/prepisZmluvyEEDvojicka?accessToken=${getAccessToken()}`,
	NOVY_ODBER_GAS: `/api/v0/pomocnik/novyOdberGAS?accessToken=${getAccessToken()}`,
	OMS_GAS: `/api/v0/pomocnik/obnovaMiestaSpotrebyGAS?accessToken=${getAccessToken()}`,
	NOVY_ODBER_GAS_PREPIS_DVOJICKA: `/api/v0/pomocnik/prepisZmluvyGASDvojicka?accessToken=${getAccessToken()}`,
	UKONCENIE_ZMLUVY_EE: `/api/v0/pomocnik/ukoncenieZmluvyEE?accessToken=${getAccessToken()}`,
	UKONCENIE_ZMLUVY_GAS: `/api/v0/pomocnik/ukoncenieZmluvyGAS?accessToken=${getAccessToken()}`,
	PREDAJ_VAS: `/api/v0/pomocnik/predajVAS?accessToken=${getAccessToken()}`,
	DODATOK_EE: `/api/v0/pomocnik/dodatokEE?accessToken=${getAccessToken()}`,
	DODATOK_GAS: `/api/v0/pomocnik/dodatokGAS?accessToken=${getAccessToken()}`,
	ZMENA_PRODUKTU_EE: `/api/v0/pomocnik/zmenaProduktuEE?accessToken=${getAccessToken()}`,
	ZMENA_PRODUKTU_GAS: `/api/v0/pomocnik/zmenaProduktuGAS?accessToken=${getAccessToken()}`,
	ANONYMNY_VSEOBECNY_UKON: `/api/v0/pomocnik/anonymnyVseobecnyUkon?accessToken=${getAccessToken()}`
}

export const recognizeMethodologicalGuideline = (ukon) => {
	const ukonTyp = get(ukon, 'typ.id')

	if (ukonTyp == UKONY_CISELNIK.VSEOBECNY_UKON_AKTIVNY) {
		return METHODOLOGICAL_GUIDELINES.VSEOBECNY_UKON_AKTIVNY
	}

	if (ukonTyp == UKONY_CISELNIK.VSEOBECNY_UKON_PASIVNY) {
		return METHODOLOGICAL_GUIDELINES.VSEOBECNY_UKON_PASIVNY
	}

	// Prepis zmluvy - typ 38, 1020 a 2020
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_PREPIS) {
		const komoditaTyp = get(ukon, 'data.odberneMiesto.komoditaTyp.id')
		if (komoditaTyp == KOMODITA_TYP.ELEKTRINA) {
			return METHODOLOGICAL_GUIDELINES.PREPIS_ZMLUVY_EE
		}
		if (komoditaTyp == KOMODITA_TYP.PLYN) {
			return METHODOLOGICAL_GUIDELINES.PREPIS_ZMLUVY_GAS
		}
	}
	// Prepis zmluvy - typ 1020
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_PREPIS_EE) {
		return METHODOLOGICAL_GUIDELINES.PREPIS_ZMLUVY_EE
	}
	// Prepis zmluvy - typ 2020
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_PREPIS_GAS) {
		return METHODOLOGICAL_GUIDELINES.PREPIS_ZMLUVY_GAS
	}

	// Nova zmluva - typ 35
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY) {
		const komoditaTyp = get(ukon, 'data.odberneMiesto.komoditaTyp.id')
		if (isEmpty(get(ukon, 'dataPred.produkt'))) {
			// Novy odber
			if (komoditaTyp == KOMODITA_TYP.ELEKTRINA) {
				return METHODOLOGICAL_GUIDELINES.NOVY_ODBER_EE
			}
			if (komoditaTyp == KOMODITA_TYP.PLYN) {
				return METHODOLOGICAL_GUIDELINES.NOVY_ODBER_GAS
			}
		} else {
			// Dvojicka prepisu
			if (komoditaTyp == KOMODITA_TYP.ELEKTRINA) {
				return METHODOLOGICAL_GUIDELINES.NOVY_ODBER_EE_PREPIS_DVOJICKA
			}
			if (komoditaTyp == KOMODITA_TYP.PLYN) {
				return METHODOLOGICAL_GUIDELINES.NOVY_ODBER_GAS_PREPIS_DVOJICKA
			}
		}
	}
	// Nova zmluva - typ 2010
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_GAS) {
		if (isEmpty(get(ukon, 'dataPred.produkt'))) {
			// Novy odber
			return METHODOLOGICAL_GUIDELINES.NOVY_ODBER_GAS
		}

		// Dvojicka prepisu
		return METHODOLOGICAL_GUIDELINES.NOVY_ODBER_GAS_PREPIS_DVOJICKA
	}
	// obnova miesta spotreby GAS - typ 2030
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_GAS_OBNOVA_MS) {
		return METHODOLOGICAL_GUIDELINES.OMS_GAS
	}
	// Nova zmluva - typ 1010, 1030
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_EE || ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_EE_OBNOVA_MS) {
		if (isEmpty(get(ukon, 'dataPred.produkt'))) {
			// Novy odber
			return METHODOLOGICAL_GUIDELINES.NOVY_ODBER_EE
		}

		// Dvojicka prepisu
		return METHODOLOGICAL_GUIDELINES.NOVY_ODBER_EE_PREPIS_DVOJICKA
	}

	// Ukoncenie zmluvy - typ 37
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_UKONCENIE) {
		const komoditaTyp = get(ukon, 'data.odberneMiesto.komoditaTyp.id')
		if (komoditaTyp == KOMODITA_TYP.ELEKTRINA) {
			return METHODOLOGICAL_GUIDELINES.UKONCENIE_ZMLUVY_EE
		}
		if (komoditaTyp == KOMODITA_TYP.PLYN) {
			return METHODOLOGICAL_GUIDELINES.UKONCENIE_ZMLUVY_GAS
		}
	}
	// Ukoncenie zmluvy - typ 1410
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_UKONCENIE_EE_PREPIS) {
		return METHODOLOGICAL_GUIDELINES.PREPIS_ZMLUVY_EE
	}
	// Ukoncenie zmluvy - typ 2410
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_UKONCENIE_GAS_PREPIS) {
		return METHODOLOGICAL_GUIDELINES.PREPIS_ZMLUVY_GAS
	}

	// Predaj VAS - typ 3010
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_VAS) {
		const komoditaTyp = get(ukon, 'data.odberneMiesto.komoditaTyp.id')
		if (komoditaTyp == KOMODITA_TYP.ELEKTRINA || komoditaTyp == KOMODITA_TYP.PLYN || komoditaTyp == KOMODITA_TYP.NEKOMODITA) {
			return METHODOLOGICAL_GUIDELINES.PREDAJ_VAS
		}
	}

	// Novy dodatok - typ 3020
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_DODATOK) {
		const komoditaTyp = get(ukon, 'data.odberneMiesto.komoditaTyp.id')
		if (komoditaTyp == KOMODITA_TYP.ELEKTRINA) {
			return METHODOLOGICAL_GUIDELINES.DODATOK_EE
		}
		if (komoditaTyp == KOMODITA_TYP.PLYN) {
			return METHODOLOGICAL_GUIDELINES.DODATOK_GAS
		}
	}

	// Zmena sadzby - typ 42
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_ZMENA) {
		const komoditaTyp = get(ukon, 'data.odberneMiesto.komoditaTyp.id')
		if (komoditaTyp == KOMODITA_TYP.ELEKTRINA) {
			return METHODOLOGICAL_GUIDELINES.ZMENA_PRODUKTU_EE
		}
		if (komoditaTyp == KOMODITA_TYP.PLYN) {
			return METHODOLOGICAL_GUIDELINES.ZMENA_PRODUKTU_GAS
		}
	}
	// Zmena sadzby - typ 1210
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_ZMENA_PRODUKTU_EE) {
		return METHODOLOGICAL_GUIDELINES.ZMENA_PRODUKTU_EE
	}
	// Zmena sadzby - typ 2210
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_ZMENA_PRODUKTU_GAS) {
		return METHODOLOGICAL_GUIDELINES.ZMENA_PRODUKTU_GAS
	}

	if (ukonTyp == UKONY_CISELNIK.ANONYMNY_VSEOBECNY_UKON) {
		return METHODOLOGICAL_GUIDELINES.ANONYMNY_VSEOBECNY_UKON
	}

	return null
}
