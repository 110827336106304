import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { get, upperFirst } from 'lodash'

// config
import { COLUMNS_COUNT } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { formatFormValue } from '../../../utils/form'

class ProduktovaRodinaField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape()
	}

	state = {}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	render() {
		const { columnsCount = COLUMNS_COUNT.THREE, value, originalValue, t } = this.props

		const valueBefore = upperFirst(get(originalValue, 'nazov'))
		const valueAfter =
			columnsCount == COLUMNS_COUNT.THREE
				? formatFormValue(get(value, 'nazov'), get(originalValue, 'nazov'), upperFirst(get(value, 'nazov')))
				: upperFirst(get(value, 'nazov'))

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('translation:Common.Produktová rodina')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
							<td>{valueAfter}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

export default compose(withTranslation('components'))(ProduktovaRodinaField)
