import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

// config
import { COLUMNS_COUNT } from '../../../containers/GenericUkon/genericUkonConfig'

class UndefinedField extends React.Component {
	static propTypes = {
		nazov: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape()
	}

	state = {}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	render() {
		const { nazov, typ, columnsCount = COLUMNS_COUNT.TWO, value, originalValue } = this.props

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr style={{ backgroundColor: '#FFD8D8' }}>
							<td>
								<strong>{`${typ} - ${nazov}`}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td style={{ wordWrap: 'break-word' }}>{JSON.stringify(originalValue)}</td>}
							<td style={{ wordWrap: 'break-word' }}>{JSON.stringify(value)}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

export default compose(withTranslation('components'))(UndefinedField)
