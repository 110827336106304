import { get } from 'lodash'
import {
	UKON_EDIT_ZU_LOAD_START,
	UKON_EDIT_ZU_LOAD_DONE,
	UKON_EDIT_ZU_LOAD_FAIL,
	NEUSHLAS_ZU_LOAD_FAIL,
	NEUSHLAS_ZU_LOAD_DONE,
	NEUSHLAS_ZU_LOAD_START,
	UKON_EDIT_ZU_CONTEXT_LOAD_START,
	UKON_EDIT_ZU_CONTEXT_LOAD_DONE,
	UKON_EDIT_ZU_CONTEXT_LOAD_FAIL
} from '../types/ukonEditZuTypes'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	zmluvnyUcet: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	nesuhlasyZu: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	contextZu: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	isLoading: false,
	isFailure: false
}

export default (state = initState, action) => {
	switch (action.type) {
		case UKON_EDIT_ZU_LOAD_START: {
			return {
				...state,
				zmluvnyUcet: {
					...state.zmluvnyUcet,
					isLoading: true,
					isFailure: false
				},
				isLoading: true,
				isFailure: false
			}
		}
		case UKON_EDIT_ZU_LOAD_DONE:
			return {
				...state,
				zmluvnyUcet: {
					data: get(action, 'payload.content', {}),
					isLoading: false,
					isFailure: false
				},
				isLoading: false,
				isFailure: false
			}
		case UKON_EDIT_ZU_LOAD_FAIL:
			return {
				...state,
				zmluvnyUcet: {
					...initState.zmluvnyUcet,
					isFailure: true
				}
			}
		case NEUSHLAS_ZU_LOAD_START:
			return {
				...state,
				nesuhlasyZu: {
					...state.nesuhlasyZu,
					isLoading: true,
					isFailure: false
				}
			}
		case NEUSHLAS_ZU_LOAD_DONE:
			return {
				...state,
				nesuhlasyZu: {
					data: get(action, 'payload.response', {}),
					isLoading: false,
					isFailure: false
				}
			}
		case NEUSHLAS_ZU_LOAD_FAIL:
			return {
				...state,
				nesuhlasyZu: {
					...initState.nesuhlasyZu,
					isFailure: true
				}
			}
		case UKON_EDIT_ZU_CONTEXT_LOAD_START:
			return {
				...state,
				contextZu: {
					...state.contextZu,
					isLoading: true,
					isFailure: false
				}
			}
		case UKON_EDIT_ZU_CONTEXT_LOAD_DONE:
			return {
				...state,
				contextZu: {
					data: get(action, 'payload.context', {}),
					isLoading: false,
					isFailure: false
				}
			}
		case UKON_EDIT_ZU_CONTEXT_LOAD_FAIL:
			return {
				...state,
				contextZu: {
					...initState.contextZu,
					isFailure: true
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
