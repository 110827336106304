import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { cloneDeep, get, isEmpty } from 'lodash'

// TODO: seatchOptionsOprientacneCislo
import { searchOptionsStat, searchOptionsObec, searchOptionsUlica, searchOptionsPsc, handleChangeAddress, revalidate } from '../AddressField/AddressFieldModel'

// actions
import * as FormAddresssesActions from '../../actions/FormAddresses'
import * as AddressActions from '../../actions/AddressActions'

// components
import AsyncSelect from '../AsyncSelect'
import AsyncCreatableSelect from '../AsyncCreatableSelect'
import Input from '../Input'
import Modal from '../../components/Modals/Modal'
import ElementLoading from '../../components/ElementLoading'

const MODE = {
	EDIT: 'edit',
	CREATE: 'create'
}

const newEmptyAddress = {
	stat: 'SK',
	obec: '',
	orientacneCislo: '',
	psc: '',
	supisneCislo: '',
	ulica: ''
}

class EditAddressModal extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		addressToEdit: PropTypes.shape(),
		allAddresses: PropTypes.array,
		onCancel: PropTypes.func,
		onSave: PropTypes.func
	}

	constructor(props) {
		super(props)

		this.state = {
			mestaOptions: [],
			uliceOptions: [],
			pscOptions: [],
			pscOptionsOptionsFromCisloDomu: [],
			orientacneCisloOptions: [],
			errors: {},
			warnings: {},
			editAddress: null
		}
	}

	componentDidMount() {
		const { allAddresses = [], addressToEdit, addressActions } = this.props

		if (addressToEdit) {
			// Editácia existujúcej adresy
			addressActions.checkUsedAddress(addressToEdit.id)
			const clonedAddress = cloneDeep(addressToEdit)

			this.setState({
				editAddress: clonedAddress
			})
		} else {
			// Vytvorenie novej adresy
			const newAddressId = this.generateNewAddressId(allAddresses)

			this.setState(
				{
					editAddress: {
						id: newAddressId,
						...newEmptyAddress
					}
				},
				() => {
					// Inicializácia validácie pri vytváraní novej adresy
					const { errors, warnings } = revalidate.call(this, this.state)
					this.setState({
						errors,
						warnings
					})
				}
			)
		}
	}

	componentWillUnmount() {
		const { addressActions } = this.props
		addressActions.checkUsedAddressClear()
	}

	// Ak vytváram novú adresu, tak na BB posielam negatívne číslo, ktoré indikuje, že ide o novú adresu
	generateNewAddressId = (allAddresses) => {
		const NEW_ADDRESS_START_INDEX = -2

		const allAddressIds = allAddresses.map((address) => address.id)
		const minIdValue = Math.min(...allAddressIds)

		let newId
		if (minIdValue < 0) {
			newId = minIdValue - 1
		} else {
			newId = NEW_ADDRESS_START_INDEX
		}

		return newId
	}

	getUsedAddressesWarning = () => {
		const { checkAddress } = this.props

		const opWarnings = checkAddress?.data?.op ?? []
		const inyOPWarnings = checkAddress?.data?.inyOP ?? []
		const zmluvneUctyWarnings = checkAddress?.data?.zmluvneUcty ?? {}
		const zmluvneUctyInyOpWarnings = checkAddress?.data?.zmluvneUctyInyOp ?? {}

		return {
			opWarnings,
			zmluvneUctyWarnings,
			zmluvneUctyInyOpWarnings,
			inyOPWarnings
		}
	}

	render() {
		const { addressToEdit, checkAddress, t, onCancel, onSave, ciselniky } = this.props
		const { editAddress, errors, warnings, mestaOptions, uliceOptions, pscOptions, pscOptionsOptionsFromCisloDomu } = this.state

		const mode = addressToEdit == null ? MODE.CREATE : MODE.EDIT

		const { opWarnings, zmluvneUctyWarnings, zmluvneUctyInyOpWarnings } = this.getUsedAddressesWarning()
		const showIsUsedWarnings = opWarnings.length > 0 || Object.keys(zmluvneUctyWarnings).length > 0 || Object.keys(zmluvneUctyInyOpWarnings).length > 0

		const statyOptions = cloneDeep(get(ciselniky, 'staty', []))
		const optionsPsc = pscOptionsOptionsFromCisloDomu.length !== 0 ? [...pscOptionsOptionsFromCisloDomu] : [...pscOptions]

		return (
			<Modal size='m' shown>
				<div className='content-wrapper'>
					<div className='modal-header'>
						<h3>{mode === MODE.EDIT ? t('atoms:AddressField.Editácia adresy') : t('atoms:AddressField.Vytvorenie adresy')}</h3>
						{mode === MODE.EDIT && (
							<h4 style={{ color: 'orange' }}>{t('atoms:AddressField.Editáciu adresy treba využívať primárne na opravu adresy!')}</h4>
						)}
					</div>
					<div className='modal-content'>
						<div className='row'>
							<div className='col-6'>
								<AsyncSelect
									value={get(editAddress, 'stat', '')}
									placeholder={t('atoms:AddressField.Štát')}
									selectOptions={statyOptions}
									onChange={(value) => handleChangeAddress.call(this, 'stat', value)}
									error={!!errors.stat}
									defaultOptions={statyOptions}
									loadOptions={searchOptionsStat.bind(this)}
								/>
								{errors.stat && <span className='text-danger'>{errors.stat}</span>}
							</div>
							<div className='col-6'>
								<AsyncCreatableSelect
									placeholder={t('atoms:AddressField.Obec')}
									selectOptions={mestaOptions}
									loadOptions={searchOptionsObec.bind(this)}
									onChange={(value) => handleChangeAddress.call(this, 'obec', value)}
									value={get(editAddress, 'obec', '')}
									isDisabled={!get(editAddress, 'stat')}
									error={!!errors.obec}
								/>
								{errors.obec && <span className='text-danger'>{errors.obec}</span>}
							</div>
						</div>
						<br />
						<div className='row'>
							<div className='col-6'>
								<AsyncCreatableSelect
									placeholder={t('atoms:AddressField.Ulica')}
									selectOptions={uliceOptions}
									loadOptions={searchOptionsUlica.bind(this)}
									onChange={(value) => handleChangeAddress.call(this, 'ulica', value)}
									value={get(editAddress, 'ulica', '')}
									isDisabled={!get(editAddress, 'obec')}
									error={!!errors.ulica}
								/>
								{errors.ulica && <span className='text-danger'>{errors.ulica}</span>}
							</div>
							<div className='col-6'>
								<Input
									placeholder={t('atoms:AddressField.Orientačné Číslo')}
									onChange={(e) => handleChangeAddress.call(this, 'orientacneCislo', e.target.value)}
									value={get(editAddress, 'orientacneCislo', '')}
									disabled={!get(editAddress, 'obec')}
									error={!!errors.orientacneCislo}
								/>
								{errors.orientacneCislo && <span className='text-danger'>{errors.orientacneCislo}</span>}
								{warnings.orientacneCislo && <span className='text-warn'>{warnings.orientacneCislo}</span>}
							</div>
						</div>
						<br />
						<div className='row'>
							<div className='col-6'>
								<Input
									placeholder={t('atoms:AddressField.Súpisné číslo')}
									onChange={(e) => handleChangeAddress.call(this, 'supisneCislo', e.target.value)}
									value={get(editAddress, 'supisneCislo', '')}
									disabled={!get(editAddress, 'obec')}
									error={!!errors.supisneCislo}
								/>
							</div>
							<div className='col-6'>
								<AsyncCreatableSelect
									placeholder={t('atoms:AddressField.PSČ')}
									selectOptions={optionsPsc}
									loadOptions={searchOptionsPsc.bind(this)}
									onChange={(value) => handleChangeAddress.call(this, 'psc', value)}
									value={get(editAddress, 'psc', '')}
									isDisabled={!get(editAddress, 'obec')}
									error={!!errors.psc}
								/>
								{errors.psc && <span className='text-danger'>{errors.psc}</span>}
							</div>
						</div>
						<br />
						<div className='row'>
							<div className='col-6'>
								<Input
									placeholder={t('atoms:AddressField.Do rúk')}
									onChange={(e) => handleChangeAddress.call(this, 'doRukMeno', e.target.value)}
									value={get(editAddress, 'doRukMeno', '')}
								/>
							</div>
						</div>

						<div style={{ position: 'relative', minHeight: '150px', maxHeight: '300px', overflow: 'auto', marginTop: '30px' }}>
							{checkAddress?.isLoading && <ElementLoading />}
							{showIsUsedWarnings && (
								<>
									<div className='text-warn'>
										<strong key='title'>{t('atoms:AddressField.Upozornenie zmena tejto adresy ovplyvní aj nasledujúce adresy')}</strong>
									</div>

									{opWarnings.length > 0 && (
										<div className='text-warn' style={{ marginTop: '10px' }}>
											<strong>{t('atoms:AddressField.Obchodný partner')}</strong>
										</div>
									)}
									{opWarnings?.map((opWarning) => {
										return (
											<div key={`op-warning-${opWarning.id}`} className='text-warn'>
												{opWarning.popis}
											</div>
										)
									})}

									{Object.entries(zmluvneUctyWarnings).map(([zuCislo, zuWarnings]) => {
										return (
											<div key={`zu-${zuCislo}-warnings`} className='text-warn' style={{ marginTop: '10px' }}>
												<strong>
													{t('atoms:AddressField.Zmluvný účet')} {zuCislo}
												</strong>

												{zuWarnings?.map((zuWarning) => {
													return (
														<div key={`zu-${zuCislo}-warning-${zuWarning.id}`} className='text-warn'>
															{zuWarning.popis}
														</div>
													)
												})}
											</div>
										)
									})}

									{/* TODO: Iny obchodny partner */}
									{/* {inyOPWarnings.length > 0 && <strong>{t('atoms:AddressField.Iný obchodný partner')}</strong>} */}

									{Object.entries(zmluvneUctyInyOpWarnings).map(([zuCislo, zuWarnings]) => {
										return (
											<div key={`zu-${zuCislo}-warnings`} className='text-warn' style={{ marginTop: '10px' }}>
												<strong>
													{t('atoms:AddressField.Zmluvný účet')} {zuCislo}
												</strong>

												{zuWarnings?.map((zuWarning) => {
													return (
														<div key={`zu-${zuCislo}-warning-${zuWarning.id}`} className='text-warn'>
															{zuWarning.popis}
														</div>
													)
												})}
											</div>
										)
									})}
								</>
							)}
						</div>
					</div>
					<div className='modal-footer'>
						<button className='button' onClick={onCancel} data-type='outline' data-color='red'>
							{t('atoms:AddressField.Zrušiť')}
						</button>
						<button className='button' onClick={() => onSave(editAddress)} data-color='green' disabled={!isEmpty(errors)}>
							{t('atoms:AddressField.Uložiť')}
						</button>
					</div>
				</div>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	addresses: state.formAddresses.data,
	obchodnyPartner: state.obchodnyPartner.detail.data,
	ciselniky: state.ciselniky.data,
	// Overenie kde všade sa existujúca adresa používa
	checkAddress: state.address.checkAddress
})

const mapDispathToProps = (dispatch) => ({
	formAddressesActions: bindActionCreators(FormAddresssesActions, dispatch),
	addressActions: bindActionCreators(AddressActions, dispatch)
})

export default compose(withTranslation('atoms'), connect(mapStateToProps, mapDispathToProps))(EditAddressModal)
