import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { get, size } from 'lodash'

// atoms
import { TextInputField } from '../../../atoms'

// utils
import { formatFormValue } from '../../../utils/form'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

class PodpisMiestoField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.string,
		auth: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape()
	}

	state = {}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true

		const { field, dispatch, auth, change, editMode, value } = this.props

		if (editMode === EDIT_MODE.CREATE || editMode === EDIT_MODE.EDIT) {
			const defaultPodpisMiesto = get(auth, 'user.podpisMiesto', '')
			if (value == null) dispatch(change(field, defaultPodpisMiesto))
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { t } = this.props

		if (value && size(value) > 100) {
			return t('translation:Common.validate.Miesto podpisu úkonu môže mať najviac 100 znakov')
		}
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, auth, t, value } = this.props

		if (editMode === EDIT_MODE.DETAIL) return null

		const valueBefore = get(auth, 'user.podpisMiesto', '')
		let valueAfter = null

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.CREATE) {
			valueAfter = (
				<Field
					name={field}
					component={TextInputField}
					validate={this.validate}
					placeholder={t('translation:Common.Zadajte miesto podpisu úkonu (ak nejde o prípad odloženého podpisu)')}
				/>
			)
		} else if (editMode == EDIT_MODE.CONFIRM) {
			valueAfter = formatFormValue(value, valueBefore)
		}

		return (
			<table className='content-table padded bordered' cellSpacing='0'>
				<tbody>
					<tr>
						<td>
							<strong>{t('translation:Common.Miesto podpisu úkonu')}</strong>
						</td>
						{columnsCount === COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
						<td>{valueAfter}</td>
					</tr>
				</tbody>
			</table>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth')
})

export default compose(withTranslation('components'), connect(mapStateToProps, null))(PodpisMiestoField)
