import axios from 'axios'
// TODO: CARE-XXX Check for solution
// import https from 'https'
import { SIGN_PDF_DONE, SIGN_PDF_FAIL, SIGN_PDF_START, SIGN_PDF_CLEAR, SIGN_PDF_CANCEL } from '../../types/podpisovanieDokumentov'
import { statusPushCreator } from '../StatusActions'
import { ERROR } from '../../utils/enums'

import config from '../../utils/config'

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

let source
let checkPdfStateInterval = null

const wacomAxios = axios.create({
	baseURL: config.wacomUrl,
	timeout: 15000
	// TODO: CARE-XXX Check for solution
	// httpsAgent: new https.Agent({
	//	rejectUnauthorized: false
	// })
})

export const signFileDone = (data) => {
	return {
		type: SIGN_PDF_DONE,
		payload: {
			data
		}
	}
}

export const signFileFail = () => {
	return {
		type: SIGN_PDF_FAIL
	}
}

export const signFileCancel = () => {
	return {
		type: SIGN_PDF_CANCEL
	}
}

export const signFileStart = () => {
	return {
		type: SIGN_PDF_START
	}
}

export const clearSignFile = () => {
	return (dispatch) => {
		dispatch({
			type: SIGN_PDF_CLEAR
		})
	}
}

function handleError(error) {
	const status = {
		typ: ERROR
	}

	if (error.response) {
		switch (error.response) {
			case 'CANCEL':
				status.popis = 'Podpisovanie bolo manuálne zrušené.'
				break
			case 'NETWORK_ERROR':
			case 'xyzmoKioskCouldNotConnect':
			case 'initXyzmoKioskCouldNotConnect':
			case 'initXyzmoKioskNotStarted':
				status.popis = 'Nepodarilo sa pripojiť k WACOM zariadeniu.'
				break
			default:
				status.popis = 'Pri podpisovaní dokumentu nastala chyba.'
		}
	} else {
		status.popis = 'Pri podpisovaní dokumentu nastala chyba.'
	}

	return status
}

/**
 *
 * @param {string} name file name
 * @param {base64} document
 * @param {string} type MIME file type
 * @param {string} signatureKey
 */
export const importSignedFile = (name, document, type, signatureKey) => {
	return (dispatch) => {
		dispatch(
			signFileDone({
				dataAsBase64: document,
				name,
				type,
				signatureKey
			})
		)
	}
}

export const cancelSigningPDF = () => {
	return async (dispatch) => {
		// cancel all requests make on axios

		try {
			source.cancel()
			clearInterval(checkPdfStateInterval)
			dispatch(signFileCancel())
			// try to cancel sign pdf on wacom and set slide show
			await wacomAxios({
				url: '/showSlideshow',
				params: { resetSign: 1, name: 'screen1' },
				method: 'GET'
			})
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		}
	}
}

export const signFile = (pdfName, base64File, signatureKey) => {
	return async (dispatch) => {
		// set source for canceling wacom requests
		source = axios.CancelToken.source()

		const wacomApiEndpoint = config.wacomUrl

		dispatch(signFileStart())

		try {
			await wacomAxios({
				url: '/showAndSignPdf?resetSign=1&showMirror=1',
				cancelToken: source.token,
				data: base64File,
				method: 'POST',
				validateStatus(status) {
					return status < 400
				},
				params: { name: pdfName }
			})
			// after a sending pdf for signing check every 2 second state of signing process
			checkPdfStateInterval = setInterval(async () => {
				try {
					const pdfStateResult = await wacomAxios({
						url: '/pdfState',
						method: 'GET',
						cancelToken: source.token,
						// POZOR, unikatny param '_' je treba koli obideniu browser cache!!
						params: { name: pdfName, _: Date.now() }
					})

					if (pdfStateResult.data.canceled || pdfStateResult.data.error) {
						clearInterval(checkPdfStateInterval)
						const errorMsg = handleError({ response: 'prerusene' })
						dispatch(statusPushCreator(errorMsg))
						dispatch(signFileFail())
						return
					}
					if (pdfStateResult.data.approved) {
						clearInterval(checkPdfStateInterval)
						const signedPdf = await wacomAxios({
							url: '/downloadPdf',
							baseURL: wacomApiEndpoint,
							cancelToken: source.token,
							method: 'GET',
							// POZOR, unikatny param '_' *MUSI* byt koli obideniu browser cache, inak moze browser vratit dokument z predchadzajuceho ukonu !!!
							params: { name: pdfName, _: Date.now() }
						})
						dispatch(
							signFileDone({
								dataAsBase64: signedPdf.data,
								name: pdfName,
								type: 'application/pdf',
								wacom: true,
								signatureKey
							})
						)

						return
					}
				} catch (e) {
					clearInterval(checkPdfStateInterval)
					const errorMsg = handleError({ response: 'prerusene' })
					dispatch(statusPushCreator(errorMsg))
					dispatch(signFileFail())
				}
			}, 2000)
		} catch (e) {
			let err
			if (e.__CANCEL__) {
				err = 'CANCEL'
			} else if (e.message === 'Network Error') {
				err = 'NETWORK_ERROR'
			} else if (e && e.response) {
				err = e.response.data
			}

			if (checkPdfStateInterval) {
				clearInterval(checkPdfStateInterval)
			}
			const errorMsg = handleError({ response: err })
			dispatch(statusPushCreator(errorMsg))
			dispatch(signFileFail())
		}
	}
}
