import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { get, indexOf, isEmpty, map } from 'lodash'

// atoms
import { DatePickerField } from '../../../atoms'

// utils
import { formatDate } from '../../../utils/date'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

class PozadovanyDatumField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		auth: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape(),
		povinny: PropTypes.bool.isRequired,
		msg: PropTypes.string,
		max: PropTypes.string,
		min: PropTypes.string,
		vyluceneDni: PropTypes.arrayOf(PropTypes.string)
	}

	state = {}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { povinny, max, min, vyluceneDni, t } = this.props

		if (value) {
			if (min && dayjs(value) < dayjs(min).startOf('day')) {
				return t('translation:Common.validate.Požadovaný dátum je mimo povolený rozsah')
			}
			if (max && dayjs(value) > dayjs(max).endOf('day')) {
				return t('translation:Common.validate.Požadovaný dátum je mimo povolený rozsah')
			}
			if (!isEmpty(vyluceneDni) && indexOf(vyluceneDni, value) >= 0) {
				return t('translation:Common.validate.Požadovaný dátum je mimo povolený rozsah')
			}
		} else if (povinny) {
			return t('translation:Common.validate.Požadovaný dátum je povinný')
		}
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, t, value, max, min, originalValue, vyluceneDni, msg } = this.props

		let minDateTime = null
		let maxDateTime = null
		if (min) {
			minDateTime = dayjs(min).startOf('day')
		}
		if (max) {
			maxDateTime = dayjs(max).endOf('day')
		}
		let excludeDates = []
		if (!isEmpty(vyluceneDni)) {
			excludeDates = map(vyluceneDni, (vylucenyDen) => new Date(vylucenyDen))
		}

		let valueBefore = null
		let valueAfter = null

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP) {
			valueAfter = (
				<div className='select-wrapper'>
					<Field
						name={field}
						component={DatePickerField}
						showYearDropdown
						scrollableYearDropdown
						minDate={minDateTime?.toDate()}
						maxDate={maxDateTime?.toDate()}
						excludeDates={excludeDates}
						validate={this.validate}
						parse={(val) => (val ? dayjs(val).format('YYYY-MM-DD') : val)}
						format={(val) => (val ? dayjs(val).format('YYYY-MM-DD') : val)}
						placeholderText={t('translation:Common.Zvoľte dátum')}
					/>
				</div>
			)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			valueAfter = formatDate(value, null, 'DD.MM.YYYY')
			if (originalValue) {
				valueBefore = formatDate(originalValue, null, 'DD.MM.YYYY')
			}
		}

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('translation:Common.Požadovaný dátum')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
							<td>
								{valueAfter}
								{msg && (
									<div className='input-note text-right' data-text-color='orange'>
										{msg}
									</div>
								)}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth')
})

export default compose(withTranslation('components'), connect(mapStateToProps, null))(PozadovanyDatumField)
