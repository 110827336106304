import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// atoms
import { DatePickerField } from '../../../atoms'

// utils
import { UNKNOWN_DATE } from '../../../utils/enums'
import { formatDate } from '../../../utils/date'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

class DatumUmrtiaField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.string,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape()
	}

	state = {}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { t, povinny } = this.props

		if (povinny && !value) {
			return t('components:GenericFields.Dátum úmrtia je povinný')
		}

		if (value && value > dayjs().endOf('day')) {
			return t('components:GenericFields.Dátum úmrtia nie je platný - nemôžete zvoliť dátum v budúcnosti')
		}
	}

	render() {
		const { field, originalValue, editMode, columnsCount = COLUMNS_COUNT.THREE, t, value } = this.props

		const isUnknownValue = dayjs(value).isSame(UNKNOWN_DATE, 'day')
		const isUnknownOriginalValue = dayjs(originalValue).isSame(UNKNOWN_DATE, 'day')

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('translation:Common.Dátum úmrtia')}</strong>
							</td>
							{(editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.CREATE) && (
								<>
									{columnsCount === COLUMNS_COUNT.THREE && (
										<td>
											{isUnknownOriginalValue
												? t('components:GenericFields.Neznámy')
												: formatDate(isUnknownOriginalValue, null, 'DD.MM.YYYY')}
										</td>
									)}
									<td>
										{isUnknownValue ? (
											t('components:GenericFields.Neznámy')
										) : (
											<div className='select-wrapper'>
												<Field
													name={field}
													component={DatePickerField}
													parse={(value) => dayjs(value).format('YYYY-MM-DD')}
													showYearDropdown
													scrollableYearDropdown
													maxDate={dayjs().endOf('day').toDate()}
													validate={this.validate}
													placeholderText={t('translation:Common.Zvoľte dátum')}
												/>
											</div>
										)}
									</td>
								</>
							)}
							{(editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) && (
								<>
									{columnsCount === COLUMNS_COUNT.THREE && (
										<td>
											{isUnknownOriginalValue
												? t('components:GenericFields.Neznámy')
												: formatDate(isUnknownOriginalValue, null, 'DD.MM.YYYY')}
										</td>
									)}
									<td>{isUnknownValue ? t('components:GenericFields.Neznámy') : formatDate(value, null, 'DD.MM.YYYY')}</td>
								</>
							)}
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

const mapStateToProps = () => ({})

export default compose(withTranslation('components'), connect(mapStateToProps, null))(DatumUmrtiaField)
