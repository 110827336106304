export const UKON_EDIT_ZU_LOAD_START = 'UKON_EDIT_ZU_LOAD_START'
export const UKON_EDIT_ZU_LOAD_DONE = 'UKON_EDIT_ZU_LOAD_DONE'
export const UKON_EDIT_ZU_LOAD_FAIL = 'UKON_EDIT_ZU_LOAD_FAIL'

export const NEUSHLAS_ZU_LOAD_START = 'NEUSHLAS_ZU_LOAD_START'
export const NEUSHLAS_ZU_LOAD_DONE = 'NEUSHLAS_ZU_LOAD_DONE'
export const NEUSHLAS_ZU_LOAD_FAIL = 'NEUSHLAS_ZU_LOAD_FAIL'

export const UKON_EDIT_ZU_CONTEXT_LOAD_START = 'UKON_EDIT_ZU_CONTEXT_LOAD_START'
export const UKON_EDIT_ZU_CONTEXT_LOAD_DONE = 'UKON_EDIT_ZU_CONTEXT_LOAD_DONE'
export const UKON_EDIT_ZU_CONTEXT_LOAD_FAIL = 'UKON_EDIT_ZU_CONTEXT_LOAD_FAIL'
