import { get } from 'lodash'
import {
	UKON_EDIT_ZU_LOAD_START,
	UKON_EDIT_ZU_LOAD_DONE,
	UKON_EDIT_ZU_LOAD_FAIL,
	NEUSHLAS_ZU_LOAD_START,
	NEUSHLAS_ZU_LOAD_DONE,
	NEUSHLAS_ZU_LOAD_FAIL,
	UKON_EDIT_ZU_CONTEXT_LOAD_DONE,
	UKON_EDIT_ZU_CONTEXT_LOAD_FAIL,
	UKON_EDIT_ZU_CONTEXT_LOAD_START
} from '../types/ukonEditZuTypes'

import { getReq, postReq } from '../utils/request'

export const loadDataForUkonEditZu = (opCislo, zuCislo) => {
	return async (dispatch) => {
		dispatch({
			type: UKON_EDIT_ZU_LOAD_START
		})

		try {
			const response = await getReq(`/api/v2/op/${opCislo}/zu/${zuCislo}/ukon-zmena-nastaveni-zu`)
			const content = get(response, 'response.content', null)

			dispatch({
				type: UKON_EDIT_ZU_LOAD_DONE,
				payload: {
					content
				}
			})

			return content
		} catch (error) {
			dispatch({
				type: UKON_EDIT_ZU_LOAD_FAIL
			})
		}
	}
}

export const loadNesuhlasForZu = (opCislo, zuCislo) => {
	return async (dispatch) => {
		dispatch({
			type: NEUSHLAS_ZU_LOAD_START
		})

		try {
			const response = await getReq(`/api/ces/nesuhlas/${opCislo}/digitalizacia/zu/${zuCislo}`)

			dispatch({
				type: NEUSHLAS_ZU_LOAD_DONE,
				payload: {
					response
				}
			})

			return response
		} catch (error) {
			dispatch({
				type: NEUSHLAS_ZU_LOAD_FAIL
			})
		}
	}
}

export const loadContext = (ukon) => {
	return async (dispatch) => {
		dispatch({
			type: UKON_EDIT_ZU_CONTEXT_LOAD_START
		})

		try {
			const contextData = await postReq(`/api/v2/ukony/process-context`, null, ukon)
			const context = get(contextData, 'response.content', null)

			dispatch({
				type: UKON_EDIT_ZU_CONTEXT_LOAD_DONE,
				payload: {
					context
				}
			})

			return context
		} catch (e) {
			dispatch({
				type: UKON_EDIT_ZU_CONTEXT_LOAD_FAIL
			})
		}
	}
}
