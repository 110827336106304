import React from 'react'
import { touch, Field } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { get, isEmpty, map, find, forEach, filter, size, includes } from 'lodash'

// atoms
import { NumericInputField } from '../../../atoms'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { TARIFA, MAX_HODNOTA_ODPOCTU } from '../../../utils/enums'
import { formatDate } from '../../../utils/date'

class OdpocetField extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		field: PropTypes.string.isRequired,
		label: PropTypes.string,
		form: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		povinny: PropTypes.bool.isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape(),
		formValues: PropTypes.shape(),
		showOnlyModes: PropTypes.arrayOf(PropTypes.string),
		hideBeforeValue: PropTypes.bool
	}

	state = {}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true

		const { dispatch, field, form, value, editMode } = this.props

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP) {
			forEach(value, (val, index) => {
				dispatch(touch(form, `${field}[${index}].hodnota`))
			})
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (fieldValue, tarifa) => {
		const { povinny, value, t } = this.props

		if (!povinny) {
			if (tarifa == TARIFA.TARIFA_NT || tarifa == TARIFA.TARIFA_VT) {
				const nt = find(value, (item) => get(item, 'tarifa') == TARIFA.TARIFA_NT)
				const vt = find(value, (item) => get(item, 'tarifa') == TARIFA.TARIFA_VT)
				if (
					fieldValue &&
					fieldValue >= 0 &&
					(get(vt, 'hodnota') == null || get(vt, 'hodnota') == undefined || get(nt, 'hodnota') == null || get(nt, 'hodnota') == undefined)
				) {
					return t('translation:Common.validate.Pri zadaní odpočtu je nutné zadať hodnotu oboch taríf')
				}
			}
			return
		}

		if (fieldValue == null || fieldValue == undefined) {
			return t('translation:Common.validate.Odpočet je povinný')
		}

		if (fieldValue > MAX_HODNOTA_ODPOCTU) {
			return t('translation:Common.validate.Hodnota odpočtu musí byť menšia ako 99999 m3')
		}
	}

	formatValue = (num, suffix) => {
		return num != null && num != undefined ? `${num.toString().split('.').join(',')} ${suffix}` : suffix
	}

	customParse = (stringValue) => {
		const val = parseInt(stringValue)
		if (isNaN(val) || val < 0) {
			return null
		}
		return val
	}

	formatStavMeradlaValue = (item, t) => {
		const obdobieDo = get(item, 'obdobieDo') ? `(${formatDate(get(item, 'obdobieDo'), '', 'DD.MM.YYYY')})` : ''
		return get(item, 'hodnota') != null && get(item, 'hodnota') != undefined
			? `${get(item, 'hodnota')} ${get(item, 'jednotka')} ${obdobieDo}`
			: t('translation:Common.neuvedené')
	}

	formatStavMeradla = (value) => {
		const { t } = this.props

		const renderTable = size(value) > 1
		const fields = map(value, (item, index) => {
			const tarifa = get(item, 'tarifa')
			if (tarifa == TARIFA.TARIFA_1T) {
				return renderTable ? (
					<tr key={`stav-meradla-${index}`}>
						<td style={{ padding: '0px', border: 'none' }}>{this.formatStavMeradlaValue(item, t)}</td>
					</tr>
				) : (
					this.formatStavMeradlaValue(item, t)
				)
			}
			if (tarifa == TARIFA.TARIFA_NT) {
				return renderTable ? (
					<tr key={`stav-meradla-${index}`}>
						<td style={{ padding: '0 10px 0 0', border: 'none' }}>{t('translation:Common.NT')}:</td>
						<td style={{ padding: '0px', textAlign: 'right', border: 'none' }}>{this.formatStavMeradlaValue(item, t)}</td>
					</tr>
				) : (
					this.formatStavMeradlaValue(item, t)
				)
			}
			if (tarifa == TARIFA.TARIFA_VT) {
				return renderTable ? (
					<tr key={`stav-meradla-${index}`}>
						<td style={{ padding: '0 10px 0 0', border: 'none' }}>{t('translation:Common.VT')}:</td>
						<td style={{ padding: '0px', textAlign: 'right', border: 'none' }}>{this.formatStavMeradlaValue(item, t)}</td>
					</tr>
				) : (
					this.formatStavMeradlaValue(item, t)
				)
			}
			if (!tarifa) {
				return renderTable ? (
					<tr key={`stav-meradla-${index}`}>
						<td style={{ padding: '0px', border: 'none' }}>{this.formatStavMeradlaValue(item, t)}</td>
					</tr>
				) : (
					this.formatStavMeradlaValue(item, t)
				)
			}
		})

		if (renderTable) {
			return (
				<table style={{ width: 'auto', marginLeft: '-2px', lineHeight: '1.8' }}>
					<tbody>{fields}</tbody>
				</table>
			)
		}

		return fields
	}

	formatStavMeradlaField = (field, item, index) => {
		const tarifa = get(item, 'tarifa')

		return (
			<Field
				name={`${field}[${index}].hodnota`}
				component={NumericInputField}
				min={0}
				precision={0}
				validate={(val) => this.validate(val, tarifa)}
				placeholder={this.formatValue(null, get(item, 'jednotka'))}
				formatValue={(val) => (val >= 0 ? this.formatValue(val, get(item, 'jednotka')) : '')}
				customParse={this.customParse}
			/>
		)
	}

	formatStavMeradlaFields = (value) => {
		const { field, t } = this.props

		const renderTable = size(value) > 1
		const fields = map(value, (item, index) => {
			const tarifa = get(item, 'tarifa')
			if (tarifa == TARIFA.TARIFA_1T) {
				return renderTable ? (
					<tr key={`stav-meradla-field-${index}`}>
						<td style={{ padding: '0px', border: 'none' }}>{this.formatStavMeradlaField(field, item, index)}</td>
					</tr>
				) : (
					this.formatStavMeradlaField(field, item, index)
				)
			}
			if (tarifa == TARIFA.TARIFA_NT) {
				return renderTable ? (
					<tr key={`stav-meradla-field-${index}`}>
						<td style={{ paddingLeft: '0px', border: 'none', width: '75px' }}>{t('translation:Common.NT')}</td>
						<td style={{ padding: '0px', textAlign: 'right', border: 'none' }}>{this.formatStavMeradlaField(field, item, index)}</td>
					</tr>
				) : (
					this.formatStavMeradlaField(field, item, index)
				)
			}
			if (tarifa == TARIFA.TARIFA_VT) {
				return renderTable ? (
					<tr key={`stav-meradla-field-${index}`}>
						<td style={{ paddingLeft: '0px', border: 'none', width: '75px' }}>{t('translation:Common.VT')}</td>
						<td style={{ padding: '0px', textAlign: 'right', border: 'none' }}>{this.formatStavMeradlaField(field, item, index)}</td>
					</tr>
				) : (
					this.formatStavMeradlaField(field, item, index)
				)
			}
			if (!tarifa) {
				return renderTable ? (
					<tr key={`stav-meradla-field-${index}`}>
						<td style={{ padding: '0px', border: 'none' }}>{this.formatStavMeradlaField(field, item, index)}</td>
					</tr>
				) : (
					this.formatStavMeradlaField(field, item, index)
				)
			}
		})

		if (renderTable) {
			return (
				<table>
					<tbody>{fields}</tbody>
				</table>
			)
		}

		return fields
	}

	render() {
		const { label, editMode, columnsCount = COLUMNS_COUNT.THREE, value, originalValue, formValues, t, showOnlyModes, hideBeforeValue } = this.props

		if (!isEmpty(showOnlyModes) && !includes(showOnlyModes, editMode)) {
			return null
		}

		let valueBefore = null
		let valueAfter = null

		const odberneMiesto = get(formValues, 'data.odberneMiesto')
		if (!hideBeforeValue && (!isEmpty(originalValue) || get(odberneMiesto, 'pristrojCislo') || !isEmpty(get(odberneMiesto, 'odpocty', [])))) {
			const pristrojCislo = get(odberneMiesto, 'pristrojCislo') ? (
				<div>
					{t('translation:Common.Číslo prístroja')}: {get(odberneMiesto, 'pristrojCislo')}
				</div>
			) : null
			const poslednyOdpocet = !isEmpty(get(odberneMiesto, 'odpocty', [])) ? (
				<div>
					{t('translation:Common.Posledný známy odpočet')}: {this.formatStavMeradla(get(odberneMiesto, 'odpocty', []))}
				</div>
			) : null
			const stavMeradla = !isEmpty(originalValue) ? (
				<div>
					{t('translation:Common.Stav zadaný druhou stranou')}: {this.formatStavMeradla(originalValue)}
				</div>
			) : null
			valueBefore = filter([pristrojCislo, poslednyOdpocet, stavMeradla], (item) => item != null)
		}

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP) {
			valueAfter = this.formatStavMeradlaFields(value)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			valueAfter = this.formatStavMeradla(value)
		}

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{label}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
							<td>{valueAfter}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(null, mapDispatchToProps))(OdpocetField)
