import React from 'react'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { get } from 'lodash'

// atoms
import OptionsPickerField from '../../../atoms/OptionsPickerField'
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

class UkonKategoriaField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		povinny: PropTypes.bool.isRequired,
		optionsData: PropTypes.string.isRequired,
		columnsCount: PropTypes.string,
		editMode: PropTypes.string
	}

	constructor(props) {
		super(props)
	}

	getOptions(optionsData) {
		const optionsToFormat = get(this.props, optionsData, [])

		return optionsToFormat.map((option) => {
			return {
				id: option?.id?.id,
				originalValue: option,
				label: option?.nazov
			}
		})
	}

	parseValue(value) {
		return value.originalValue
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { t, povinny } = this.props

		if (povinny && !value) {
			return t('translation:Common.validate.Zvoľte kategóriu')
		}
	}

	render() {
		const { field, optionsData, columnsCount = COLUMNS_COUNT.THREE, value, label, editMode } = this.props

		const options = this.getOptions(optionsData)

		if (editMode == EDIT_MODE.DETAIL) {
			return null
		}

		const valueBefore = <td />
		let valueAfter = null

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP || editMode == EDIT_MODE.CREATE) {
			valueAfter = (
				<td>
					<Field
						name={field}
						selectedOptionId={value?.id?.id}
						component={OptionsPickerField}
						options={options}
						parse={this.parseValue}
						validate={this.validate}
					/>
				</td>
			)
		} else if (editMode == EDIT_MODE.CONFIRM) {
			valueAfter = (
				<td>
					<strong>{options?.find((option) => value?.id?.id === option.id)?.label}</strong>
				</td>
			)
		}

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{label}</strong>
							</td>
							{columnsCount === COLUMNS_COUNT.THREE && valueBefore}
							{valueAfter}
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

export default compose(withTranslation('components'))(UkonKategoriaField)
